import { Fade, Modal } from "@material-ui/core"
import React, { useState } from "react"
import { Carousel } from "react-responsive-carousel"
import { STATIC_URL } from "src/constants"
import MMButton from "../../common-components/MMButton/MMButton"
import { MMExperience, OwnRating, PastEventData } from "../../types"
import ExperienceInfos from "./ExperienceInfos"
import styles from "./ExperienceMobile.module.scss"
import ExperienceRatingsBlock from "./ExperienceRatingsBlock"
import ExperienceTitle from "./ExperienceTitle"
import Order from "./Order/Order"

interface Props {
  experience: MMExperience
}

const ExperienceMobile: React.FC<Props> = ({ experience }) => {
  const [isOrderModalOpen, setIsOrderModalOpen] = useState(false)

  const carouselElements = experience.imageNames.map((img, index) => {
    return <img className={styles.carouselElement} key={index} src={STATIC_URL + img} />
  })

  return (
    <>
      <ExperienceTitle experience={experience} />
      <div
        style={{ width: "100%", height: window.innerWidth * 1.5, maxHeight: "70%" }}
        className={styles.carouselContainer}
      >
        <Carousel
          swipeable={true}
          dynamicHeight
          infiniteLoop
          autoPlay={false}
          interval={14400000}
          swipeScrollTolerance={50}
          showArrows={false}
          showThumbs={false}
          showStatus={false}
          showIndicators={true}
        >
          {carouselElements}
        </Carousel>
      </div>
      <div className={styles.infoPart}>
        <ExperienceInfos experience={experience} />
        <ExperienceRatingsBlock
          numOfRatings={experience.numOfRatings}
          avgScore={experience.avgScore}
        />
        <div className={styles.bottom}>
          <MMButton handleClick={() => setIsOrderModalOpen(true)} theme="fab">
            Dátumok mutatása
          </MMButton>
        </div>
      </div>
      <Modal
        onClose={() => setIsOrderModalOpen(false)}
        closeAfterTransition
        open={isOrderModalOpen}
      >
        <Fade in={isOrderModalOpen}>
          <div className={styles.orderContainer}>
            <Order
              isOpen={isOrderModalOpen}
              experience={experience}
              setIsOpen={setIsOrderModalOpen}
            />
          </div>
        </Fade>
      </Modal>
    </>
  )
}
export default ExperienceMobile
