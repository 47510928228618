import axios from "axios"
import { APIBASEURL } from "../constants"
import { RegisterApiResponse, RegisterBody } from "../types"
import { hash } from "../utils/misc"

export const register = async (data: RegisterBody) => {
	data.password = hash(data.password)

	const res = await axios({
		method: "post",
		url: APIBASEURL + "register",
		data,
		withCredentials: true,
	})

	return res.data as RegisterApiResponse
}
