import axios from "axios"
import { APIBASEURL } from "../constants"
import { OwnRating, RegisterApiResponse } from "../types"

export const patchRating = async (data: OwnRating & { orderID: number }) => {
	const res = await axios({
		method: "patch",
		url: APIBASEURL + "order",
		data,
		withCredentials: true,
	})

	return res.data as RegisterApiResponse
}
