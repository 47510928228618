import React from "react"
import { JsxElement } from "typescript"
import styles from "./MMCheckbox.module.scss"

export interface CheckboxProps {
	label: string | JSX.Element
	value: boolean
	setValue: (val: boolean) => void
	className?: string
}

const MMCheckbox: React.FC<CheckboxProps> = ({ label, setValue, value, className }) => {
	return (
		<div className={[styles.container, className ?? ""].join(" ")}>
			<div
				className={[styles.box, value ? styles.checked : ""].join(" ")}
				onClick={() => setValue(!value)}
			>
				<i className="material-icons">check</i>
			</div>
			<p>{label}</p>
		</div>
	)
}
export default MMCheckbox
