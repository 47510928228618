import axios from 'axios'
import { APIBASEURL } from '../constants'
import { ApiResponse } from '../types'

export const saveExperience = async (experienceID: number) => {
  const res = await axios({
    method: 'post',
    url: APIBASEURL + 'save-experience',
    data: { experienceID },
    withCredentials: true,
  })

  return res.data as ApiResponse
}
