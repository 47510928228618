import React, { useContext } from "react"
import { GlobalContext } from "../../../../App"
import FinishOrderLoggedIn from "./FinishOrderLoggedIn"
import FinishOrderNotLoggedIn from "./FinishOrderNotLoggedIn"
import styles from "./FinishOrderWrapper.module.css"

interface Props {
  open: boolean
  setOpen: (val: boolean) => void
  eventID: number
  onFinished: () => void
}

const FinishOrderWrapper: React.FC<Props> = ({ open, setOpen, eventID }) => {
  const { user } = useContext(GlobalContext)

  return user ? (
    <FinishOrderLoggedIn eventID={eventID} open={open} setOpen={setOpen} />
  ) : (
    <FinishOrderNotLoggedIn eventID={eventID} open={open} setOpen={setOpen} />
  )
}
export default FinishOrderWrapper
