import { InputAdornment, TextField } from "@material-ui/core"
import React, { useState } from "react"
import MMTooltip from "../MMTooltip/MMTooltip"
import styles from "./MMInput.module.scss"

interface Props {
  value: string
  setValue: (v: string) => void
  className?: string
  icon?: string
  label?: string
  name?: string
  type?: "password"
  errorText?: string
  helperText?: string
  rows?: number
  variant?: "outlined" | "standard"
  size?: "small" | "medium"
  disabled?: boolean
  onEnter?: () => void
  onBlur?: () => void
}

const PasswordHideAdornment: React.FC<{
  dynType: "password" | undefined
  setDynType: (v: "password" | undefined) => void
}> = ({ dynType, setDynType }) => {
  const onClick = () => {
    if (dynType === "password") setDynType(undefined)
    else setDynType("password")
  }

  return (
    <InputAdornment position="start">
      <i onClick={onClick} className={["material-icons", styles.icon, styles.peekIcon].join(" ")}>
        {!dynType ? "visibility" : "visibility_off"}
      </i>
    </InputAdornment>
  )
}

const ErrorAdornment: React.FC<{ errorText?: string; position: "start" | "end" }> = ({
  errorText,
  position,
}) => {
  if (!errorText) return null

  return (
    <InputAdornment position={position}>
      <MMTooltip title={errorText}>
        <i className={["material-icons", styles.icon, styles.errorIcon].join(" ")}>error</i>
      </MMTooltip>
    </InputAdornment>
  )
}

const MMInput: React.FC<Props> = ({
  setValue,
  value,
  className,
  icon,
  label,
  name,
  type,
  errorText,
  helperText,
  rows,
  variant = "outlined",
  size = "small",
  onEnter,
  onBlur,
  disabled,
}) => {
  const [dynamicType, setDynamicType] = useState<"password" | undefined>(type || undefined)

  const peekAdornment =
    type === "password" ? (
      <PasswordHideAdornment dynType={dynamicType} setDynType={setDynamicType} />
    ) : null

  function handleKeyPress(e: React.KeyboardEvent<HTMLDivElement>) {
    if (e.key === "Enter" && onEnter) onEnter()
  }

  return (
    <TextField
      color="secondary"
      rows={rows}
      multiline={!!rows}
      variant={variant}
      className={[styles.container, className].join(" ")}
      value={value}
      onChange={({ target }) => setValue(target.value)}
      label={label}
      size={size}
      type={dynamicType}
      name={name}
      error={!!errorText}
      helperText={helperText}
      disabled={disabled}
      InputProps={{
        onKeyPress: handleKeyPress,
        onBlur: (e: any) => onBlur?.(),
        startAdornment:
          peekAdornment && errorText ? (
            <ErrorAdornment errorText={errorText} position="start" />
          ) : icon ? (
            <InputAdornment position="start">
              <i className={["material-icons", styles.icon].join(" ")}>{icon}</i>
            </InputAdornment>
          ) : undefined,
        endAdornment: peekAdornment ? (
          peekAdornment
        ) : errorText ? (
          <ErrorAdornment errorText={errorText} position="end" />
        ) : undefined,
      }}
    ></TextField>
  )
}

export default MMInput
