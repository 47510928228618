import axios from "axios"
import { APIBASEURL } from "../constants"
import { LoginApiResponse, LoginBody, ApiResponse, MMExperience } from "../types"
import { hash } from "../utils/misc"

export const getExperience = async (experienceID: number) => {
	const res = await axios({
		method: "get",
		url: APIBASEURL + "experiences/" + experienceID,
		withCredentials: true,
	})

	return res.data as ApiResponse<MMExperience>
}
