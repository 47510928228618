import React, { useContext, useState } from "react"
import { GlobalContext } from "../../../../App"
import MMButton from "../../../../common-components/MMButton/MMButton"
import MMInput from "../../../../common-components/MMInput/MMInput"
import MMModal from "../../../../common-components/MMModal/MMModal"
import { Address } from "../../../../types"
import { useBillingFields } from "../../../../utils/hooks"
import { validateEmail } from "../../../../utils/validations"
import AuthModal from "../../../Home/AuthModal/AuthModal"
import BillingInformation from "./BillingInformation"
import styles from "./FinishOrderNotLoggedIn.module.scss"
import { handeToPaymentClick, makeCreateGuestOrderPayload } from "./finishOrderUtils"

export type GuestOrderNecessaryFields = keyof Address | "fullName" | "email"

const validate = (values: { [key: string]: any }) => {
  const necessaryFields: GuestOrderNecessaryFields[] = [
    "city",
    "zipCode",
    "addressLine1",
    "fullName",
    "email",
  ]
  const errors = {} as { [key in GuestOrderNecessaryFields]: string }
  necessaryFields.forEach((name) => {
    if (!values[name]) errors[name] = "Szükséges mező"
  })

  if (!errors.email && !validateEmail(values.email)) {
    errors.email = "Helytelen email cím"
  }

  return errors
}

interface Props {
  open: boolean
  setOpen: (val: boolean) => void
  eventID: number
}

const FinishOrderNotLoggedIn: React.FC<Props> = ({ open, setOpen, eventID }) => {
  const { numOfGuests, user } = useContext(GlobalContext)

  const [continuingAsGuest, setContinuingAsGuest] = useState(false)
  const [authModalOpen, setAuthModalOpen] = useState(false)
  const [email, setEmail] = useState("")
  const [errors, setErrors] = useState<{ [key in GuestOrderNecessaryFields]: boolean } | null>(null)
  const billingProps = useBillingFields(user, errors)

  const closeSelf = () => {
    setOpen(false)
    setTimeout(() => setContinuingAsGuest(false), 200)
  }

  const openAuthModal = () => {
    setAuthModalOpen(true)
    setOpen(false)
  }

  const closeAuthModal = () => {
    setAuthModalOpen(false)
    setOpen(true)
  }

  const values = makeCreateGuestOrderPayload({
    billingProps,
    email,
    eventID,
    numOfGuests: numOfGuests || 1,
  })

  const onSubmit = () => handeToPaymentClick(setErrors, validate, values)

  return (
    <>
      <MMModal open={open} title="Utolsó lépések" handleClose={closeSelf}>
        <MMButton
          handleClick={openAuthModal}
          className={styles.button}
          theme={continuingAsGuest ? undefined : "callToAction"}
        >
          Bejelentkezés vagy regisztráció
        </MMButton>
        {!continuingAsGuest ? (
          <MMButton className={styles.button} handleClick={() => setContinuingAsGuest(true)}>
            Folytatás vendégként
          </MMButton>
        ) : null}
        {continuingAsGuest ? (
          <div className={styles.inputBlock}>
            <MMInput
              value={email}
              setValue={setEmail}
              label="Email cím"
              errorText={typeof errors?.email === "string" ? errors.email : undefined}
            />
            <BillingInformation {...billingProps} />
            <MMButton handleClick={onSubmit} theme="callToAction">
              Foglalás
            </MMButton>
          </div>
        ) : null}
      </MMModal>
      <AuthModal open={authModalOpen} handleClose={closeAuthModal} />
    </>
  )
}

export default FinishOrderNotLoggedIn
