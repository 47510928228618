import axios from "axios"
import { APIBASEURL } from "src/constants"
import { ApiResponse, MMExperience } from "src/types"

export const getExperiencesAdmin = async (query: string) => {
  const res = await axios({
    method: "GET",
    url: APIBASEURL + "admin/experience",
    params: {
      search: query,
    },
    withCredentials: true,
  })

  return res.data as ApiResponse<MMExperience[]>
}
