import axios, { AxiosResponse } from "axios"
import { APIBASEURL } from "../constants"
import { ApiResponse, GuestCreationData, OrderCreationData } from "../types"

export const createOrder = async (
  data: OrderCreationData | (OrderCreationData & GuestCreationData)
) => {
  const res = await axios({
    method: "post",
    url: APIBASEURL + "order",
    withCredentials: true,
    data,
  })

  return res as AxiosResponse<ApiResponse<string>>
}
