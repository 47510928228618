import React, { useContext, useState, useEffect } from "react"
import { GlobalContext } from "../../../App"
import MMButton from "../../../common-components/MMButton/MMButton"
import { MMEvent, MMExperience, Order, OrderEventType, MMExperienceBasicData } from '../../../types';
import styles from "./OrderEvent.module.scss"
import MMTooltip from "src/common-components/MMTooltip/MMTooltip"
import MMModal from 'src/common-components/MMModal/MMModal'
import { refundEvent } from 'src/apicalls/refundEvent'
import { TextField } from '@material-ui/core';

interface Props {
  event: MMEvent
  experience: MMExperience
  onSelectClick: () => void
}

const OrderEvent: React.FC<Props> = ({ event, experience, onSelectClick }) => {
  const { numOfGuests, user } = useContext(GlobalContext)
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false)
  const [orders, setOrders] = useState<{  event: OrderEventType; experience: MMExperienceBasicData;  }[]>([])
  const [selectedOrder, setSelectedOrder] = useState<OrderEventType | null>(null);
  const [refundNumberOfGuest, setRefundNumberOfGuest] = useState(1)

  const startDate = new Date(event.startTime * 1000)
  const endDate = new Date((event.startTime + experience.lengthInHours * 3600) * 1000)

  useEffect(() => {
    if(user){
      const o = user?.futureEvents.filter((e) => e.event.id === event.id)
      if (o && o?.length > 0) {
        setOrders(o)
      }
    }
  }, [user])

  const handleCancelEvent = async (orderId: number) => {
    refundEvent(orderId);
    handleCloseRefundModal();
  }

  const handleCloseRefundModal = () => {
    setIsCancelModalOpen(false);
    setSelectedOrder(null)
  }

  return (
    <div className={styles.container}>
      <MMModal
        open={isCancelModalOpen}
        title="Jelentkezés lemondása"
        handleClose={() => setIsCancelModalOpen(false)}
      >
        <h3 className={styles.modalQuestion}>
          Foglalás lemondása ({selectedOrder && selectedOrder.numOfGuests} fő)
        </h3>
        <p>Sajnáljuk, hogy nem tud résztvenni a(z) {experience.name} workshopon. A lemondás megerősítése esetén a befizettet összeget 5 munkanapon belül utaljuk vissza a számlájára. További workshop időpontokért forduljon hozzánk bizalommal az info@marminalunk.hu e-mail címen vagy érdeklődjön a kézműves mestere által megadott elérhetőségeken.</p>
        <div className={styles.modalButtonContainer}>
          <MMButton className={[styles.btnCancel].join(" ")} handleClick={
            () => selectedOrder && handleCancelEvent(selectedOrder.orderId)
          }
            theme="callToAction">
            Lemondás
          </MMButton>
          <MMButton className={styles.button} handleClick={handleCloseRefundModal}>
            Bezárás
          </MMButton>
        </div>
      </MMModal>
      <div className={styles.data}>
        <p>{new Date(event.startTime * 1000).toLocaleDateString("hu", { dateStyle: "long" })}</p>
        <p>
          {startDate.toLocaleTimeString("hu", { timeStyle: "short" })} -{" "}
          {endDate.toLocaleTimeString("hu", { timeStyle: "short" })}
        </p>
      </div>
      <div>
        {numOfGuests === null ? (
          <MMTooltip arrow placement="top" title="Adja meg a vendégek számát!">
            <div>
              <MMButton disabled handleClick={onSelectClick} theme="callToAction">
                Foglalás
              </MMButton>
            </div>
          </MMTooltip>
        ) : (
          <MMButton handleClick={onSelectClick} theme="callToAction">
            Foglalás
          </MMButton>
        )}
        {
          orders.length > 0 && orders.map((o) => (
            <MMButton handleClick={() => {
              setIsCancelModalOpen(true)
              setSelectedOrder(o.event)
            }} className={styles.btnCancel} theme="callToAction">
              Lemondás {`(${o.event.numOfGuests} fő)`}
            </MMButton>
          ))
        }
      </div>
    </div>
  )
}
export default OrderEvent
