import axios, { AxiosResponse } from "axios"
import { APIBASEURL } from "../constants"
import { ApiResponse, PastEventData, Rating } from "../types"

export const getRatings = async ({
	experienceID,
	score,
}: {
	experienceID: number
	score?: number[]
}) => {
	try {
		const res = await axios({
			method: "get",
			url: APIBASEURL + "experiences/" + experienceID + "/ratings",
			withCredentials: true,
			params: { score },
		})
		return res.data as ApiResponse<Rating[]>
	} catch {
		return false
	}
}
