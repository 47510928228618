export function validateEmail(mail: string) {
	if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(mail)) {
		return true
	}
	return false
}

export function checkPasswordComplexity(pw: string) {
	const exp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/
	if (exp.test(pw)) {
		return true
	} else {
		return false
	}
}
