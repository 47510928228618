import axios from "axios"
import { APIBASEURL } from "src/constants"
import { ApiResponse, ExperienceImage } from "src/types"

export const getExperienceImagesMasterAdmin = async (experienceID: number) => {
  const res = await axios({
    method: "GET",
    url: APIBASEURL + `master-admin/experience/${experienceID}/images`,
    withCredentials: true,
  })

  return res.data as ApiResponse<ExperienceImage[]>
}
