import React, { useContext, useEffect, useState } from "react"
import { useHistory } from "react-router"
import { GlobalContext } from "../../App"
import { getExperiencesAdmin as getExperiencesMasterAdmin} from "src/apicalls/CraftsmanAdmin/getExperiencesAdmin"
import { getExperiencesAdmin } from "src/apicalls/admin/getExperiencesAdmin"
import Loader from "src/common-components/Loader/Loader"
import MMInput from "src/common-components/MMInput/MMInput"
import ExperienceDisplay from "./ExperienceDisplay"
import styles from "./Admin.module.scss"
import { useDebouncedState } from "src/utils/hooks"
import { MMExperience } from "src/types"
import MMButton from "../../common-components/MMButton/MMButton"
import { createExperienceAdmin as createExperienceMasterAdmin } from "src/apicalls/CraftsmanAdmin/createExperienceAdmin"
import { createExperienceAdmin } from "src/apicalls/admin/createExperienceAdmin"
import { getunVerifiedExperiencesAdmin } from '../../apicalls/admin/getunVerifiedExperiencesAdmin';
import { setExperienceToVerifiedAdmin } from 'src/apicalls/admin/setExperienceToVerifiedAdmin'

const Admin: React.FC<{isCraftsman?: boolean}> = ({isCraftsman = false}) => {
  const { user } = useContext(GlobalContext)
  const history = useHistory()
  const [loading, setLoading] = useState(true)
  const [experienceList, setExperienceList] = useState<MMExperience[] | null>(null)
  const [masterExperienceList, setMasterExperienceList] = useState<MMExperience[] | null>(null)

  const [searchQuery, updateSignal, setSearchQuery] = useDebouncedState("", 500)

  useEffect(() => {
    if (!loading) {
      updateExperienceList()
      if (!isCraftsman) {
        updateMasterExperienceList()
      }
    }
  }, [updateSignal])

  useEffect(() => {
    if (user) {
      if (!user.isAdmin && !user.isMaster) {
        history.replace("/")
      } else {
        setLoading(false)
      }
    }
  }, [user])

  async function updateExperienceList() {
    setExperienceList(null)
    if (isCraftsman) {
      const r = await getExperiencesMasterAdmin()
      if (r.result === "ok") setExperienceList(r.data)
    } else {
      const r = await getExperiencesAdmin(searchQuery)
      if (r.result === "ok") setExperienceList(r.data)
    }
  }

  async function updateMasterExperienceList() {
    setExperienceList(null)
    const r = await getunVerifiedExperiencesAdmin()
    if (r.result === "ok") setMasterExperienceList(r.data)
  }

  const createExperience = async () => {
    if (isCraftsman && user?.masterId) {
      const res = await createExperienceMasterAdmin(user?.masterId)
      setExperienceList(res.data)
    } else {
      const res = await createExperienceAdmin()
      setExperienceList(res.data)
    }
  }

  const verifyExperience = async (id: number) => {
    const res = await setExperienceToVerifiedAdmin(id);
    console.log(res)
    if (res.verified) {
      setExperienceList(res.verified)
    }
    if (res.unverified) {
      setMasterExperienceList(res.unverified)
    }
  }

  if (!user) return (
    <div className={styles.container}>
      <p className={styles.blockTitle}>Kérlek jelentkezz be adminisztrátorként!</p>
    </div>
  )
  if (loading) return <Loader />
  return (
    <div className={styles.container}>
      <div className={styles.top}>
        <MMInput
          label="Keresés program vagy szervező nevében"
          value={searchQuery}
          icon={searchQuery !== "" ? "search" : undefined}
          setValue={setSearchQuery}
        />
        <MMButton theme="callToAction" handleClick={createExperience}>
          Új program
        </MMButton>
      </div>
      {experienceList === null ? (
        <div className={styles.loader}>
          <Loader />
        </div>
      ) : (
        <div className={styles.experienceList}>
          {experienceList.length === 0 ? (
            <div className={styles.placeholder}>Nincs a keresésnek megfelelő találat</div>
          ) : (
            experienceList.map((e) => (
              <ExperienceDisplay
                key={e.id}
                experience={e}
                refreshExperiences={updateExperienceList}
              />
            ))
          )}
        </div>
      )}
      {
        !isCraftsman &&
        <>
          <h4 className={styles.unverifiedTitle}>Elfogadásra váró workshopok</h4>
          {masterExperienceList === null ? (
            <div className={styles.loader}>
              <Loader />
            </div>
          ) : (
            <div className={styles.experienceList}>
              {masterExperienceList.length === 0 ? (
                <div className={styles.placeholder}>Nincs a keresésnek megfelelő találat</div>
              ) : (
                masterExperienceList.map((e) => (
                  <div className={styles.masterExperiences}>
                    <ExperienceDisplay
                      key={e.id}
                      experience={e}
                      refreshExperiences={updateMasterExperienceList}
                    />
                    <div className={styles.acceptButton}>
                    </div>
                    <MMButton theme="callToAction" handleClick={() => {verifyExperience(e.id)}}>
                      Elfogadom
                    </MMButton>
                  </div>
                ))
              )}
            </div>
          )}
        </>
      }
    </div>
  )
}
export default Admin
