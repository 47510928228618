import axios from "axios"
import { APIBASEURL } from "../constants"
import { LoginApiResponse, LoginBody } from "../types"
import { hash } from "../utils/misc"

export const login = async (data?: LoginBody) => {
	if (data) data.password = hash(data.password)
	const res = await axios({
		method: "post",
		url: APIBASEURL + "login",
		data,
		withCredentials: true,
	})

	return res.data as LoginApiResponse
}
