import React, { ReactElement, useState } from "react"
import styles from "./SlideLayout.module.scss"

interface Props {
	customTransformed?: boolean
	customSetTransformed?: (val: boolean) => void
	children: [
		React.FunctionComponentElement<{
			setTransformed: React.Dispatch<React.SetStateAction<boolean>>
		}>,
		React.FunctionComponentElement<{
			setTransformed: React.Dispatch<React.SetStateAction<boolean>>
		}>
	]
	startingTransformed?: boolean
}

const SlideLayout: React.FC<Props> = ({
	children,
	startingTransformed = false,
	customTransformed,
	customSetTransformed,
}) => {
	const [transformed, setTransformed] = useState<boolean>(startingTransformed)

	let relevantTransformed = customTransformed !== undefined ? customTransformed : transformed
	let relevantSetTransformed =
		customSetTransformed !== undefined ? customSetTransformed : setTransformed

	const childrenWithProp = React.Children.map(children, (child) => (
		<div className={styles.childContainer}>
			{React.cloneElement(child as any, { setTransformed: relevantSetTransformed })}
		</div>
	))

	return (
		<div className={styles.wrapper}>
			<div
				className={styles.slideContainer}
				style={{ transform: `translateX(${relevantTransformed ? "-50%" : 0})` }}
			>
				{childrenWithProp}
			</div>
		</div>
	)
}
export default SlideLayout
