import axios from "axios"
import { APIBASEURL } from "../constants"
import { ApiResponse, BillingInformation, OwnRating, RegisterApiResponse, User } from "../types"

export const patchUser = async (
	data: Partial<Omit<User, "billingInformation"> & BillingInformation>
) => {
	const res = await axios({
		method: "patch",
		url: APIBASEURL + "user",
		data,
		withCredentials: true,
	})

	return res.data as ApiResponse<any, User>
}
