import axios, { AxiosResponse } from "axios"
import { APIBASEURL } from "../../constants"
import { ApiResponse, PastEventData, Rating } from "../../types"

export const getMaster = async (id: number) => {
	try {
		const res = await axios({
			method: "get",
			url: APIBASEURL + "master/" + id,
			withCredentials: true,
		})
		return res.data
	} catch {
		return false
	}
}
