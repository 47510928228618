import React, { useContext, useEffect, useState } from "react"
import { useHistory } from "react-router"
import { GlobalContext } from "../../../App"
import { getExperiencesAdmin } from "src/apicalls/admin/getExperiencesAdmin"
import Loader from "src/common-components/Loader/Loader"
import MMInput from "src/common-components/MMInput/MMInput"
import ExperienceDisplay from "../ExperienceDisplay"
import styles from "../Admin.module.scss"
import { useDebouncedState } from "src/utils/hooks"
import { MMExperience } from "src/types"
import MMButton from "../../../common-components/MMButton/MMButton"
import { createExperienceAdmin } from "../../../apicalls/admin/createExperienceAdmin"
import {
  Box,
  Tabs,
  Tab,
  Typography,
} from '@material-ui/core'
import Financial from './Financial'
import Profile from './Profile';
import Admin from '../Admin';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          {children}
        </Box>
      )}
    </div>
  );
}

const CraftsmanAdmin: React.FC = () => {
  const { user } = useContext(GlobalContext)
  const history = useHistory()
  const [loading, setLoading] = useState(false)
  const [experienceList, setExperienceList] = useState<MMExperience[] | null>(null)

  const [tabValue, setTabValue] = React.useState(0);

  const [searchQuery, updateSignal, setSearchQuery] = useDebouncedState("", 500)

  useEffect(() => {
    if (!loading) {
      //updateExperienceList()
    }
  }, [updateSignal])

  useEffect(() => {
    if (user) {
      if (!user.isMaster) {
        history.replace("/")
      } else {
        setLoading(false)
      }
    }
  }, [user])

  const a11yProps = (index: number) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  // if (loading) return <Loader />
  return (
    <div className={[styles.container, styles.craftsmanData].join("")}>
      <Box>
        <Tabs
          value={tabValue}
          onChange={(e, newValue) => {setTabValue(newValue)}}
          aria-label="basic tabs example"
        >
          <Tab label="Programfeltöltés" {...a11yProps(0)} />
          <Tab label="Kézműves profil" {...a11yProps(1)} />
          <Tab label="Pénzügyek" {...a11yProps(2)} />
        </Tabs>
      </Box>
      <TabPanel value={tabValue} index={0}>
        <Admin isCraftsman={true} />
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <Profile />
      </TabPanel>
      <TabPanel value={tabValue} index={2}>
        <Financial />
      </TabPanel>
      {/* <MMButton handleClick={handleSavePreferences} className={styles.saveButton} theme="callToAction">
        Mentés
      </MMButton> */}
      {/* <div className={styles.top}>
        <MMInput
          label="Keresés program vagy szervező nevében"
          value={searchQuery}
          icon={searchQuery !== "" ? "search" : undefined}
          setValue={setSearchQuery}
        />
        <MMButton theme="callToAction" handleClick={createExperience}>
          Új program
        </MMButton>
      </div> */}
      {/* {experienceList === null ? (
        <div className={styles.loader}>
          <Loader />
        </div>
      ) : (
        <div className={styles.experienceList}>
          {experienceList.length === 0 ? (
            <div className={styles.placeholder}>Nincs a keresésnek megfelelő találat</div>
          ) : (
            experienceList.map((e) => (
              <ExperienceDisplay
                key={e.id}
                experience={e}
                refreshExperiences={updateExperienceList}
              />
            ))
          )}
        </div>
      )} */}
    </div>
  )
}

export default CraftsmanAdmin
