import React, { useEffect } from "react"
import Loader from "../../common-components/Loader/Loader"
import { useRatings } from "../../utils/hooks"
import styles from "./Ratings.module.scss"
import SingleRating from "./SingleRating"
const Ratings: React.FC = () => {
	const [ratings] = useRatings()

	if (!ratings)
		return (
			<div>
				<Loader width="5rem" />
			</div>
		)

	const ratingElements = ratings.map((rating, index) => (
		<div className={styles.ratingContainer}>
			<SingleRating {...rating} key={index} />
		</div>
	))

	return (
		<div>
			<div className={styles.ratings}>{ratingElements}</div>
		</div>
	)
}
export default Ratings
