import { Button, useMediaQuery } from "@material-ui/core"
import React, { useContext, useState } from "react"
import { useHistory } from "react-router"
import { GlobalContext } from "../../App"
import AuthModal from "../../pages/Home/AuthModal/AuthModal"
import MMButton from "../MMButton/MMButton"
import MMTooltip from "../MMTooltip/MMTooltip"
import styles from "./Header.module.scss"
import SearchPopup from "./SearchPopup"
import CraftsmanModal from '../../pages/Home/AuthModal/CraftsmanModal';

const Header: React.FC = () => {
  const { user, setSearchStart, setNumOfGuests, setSearchEnd } = useContext(GlobalContext)
  const history = useHistory()

  const [isAuthModalOpen, setIsAuthModalOpen] = useState(false)
  const [isCraftsmanModalOpen, setIsCraftsmanModalOpen] = useState(false)
  const [isSearchModalOpen, setIsSearchModalOpen] = useState(false)

  const isMobile = useMediaQuery("(max-width: 600px)")

  const handleProfileClick = () => {
    if (user) history.push({ pathname: "/profile" })
    else setIsAuthModalOpen((s) => !s)
  }

  const handleHomeClick = () => {
    setSearchEnd(null)
    setSearchStart(null)
    setNumOfGuests(1)
    history.push({ pathname: "/" })
  }

  const onSearchClick = () => {
    history.push({ pathname: "/listings" })
    setIsSearchModalOpen(true)
  }

  return (
    <div className={styles.container}>
      <div className={styles.box}>
        <img
          draggable={false}
          src="/logo/mm-logo-color.svg"
          onClick={handleHomeClick}
          className={styles.logo}
        />
      </div>
      {isMobile ? (
        <div onClick={onSearchClick} className={[styles.mobileSearch].join(" ")}>
          <i className={"material-icons"}>search</i>
          <p>Keresés</p>
        </div>
      ) : (
        <div className={[styles.box, styles.searchButton].join(" ")}>
          <MMButton handleClick={onSearchClick} icon="search">
            {"Keresés vagy szűrés a programokban"}
          </MMButton>
        </div>
      )}
      {!isMobile && user?.isAdmin ? (
        <MMButton
          handleClick={() => {
            history.push("/admin")
          }}
          icon="vpn_key"
        >
          {"Admin"}
        </MMButton>
      ) : null}
      {
        user?.isMaster&& (
          <MMButton
            handleClick={() => {
              history.push({ pathname: "/craftsman" })
            }}
            icon="vpn_key"
          >
            "Kézműves Admin"
          </MMButton>
        )
      }
      <div className={[styles.box, styles.account].join(" ")}>
        {/* {!user && <div className={styles.clickable}>
          <Button
            onClick={() => {
              setIsCraftsmanModalOpen(true);
            }}
            color="primary"
            variant='contained'
            className={styles.btnCraftsmanLogin}
          >
            Regisztrálás kézművesként
          </Button>
        </div>} */}
        <div>
          <div className={styles.clickable}>
            {user ?
              <div onClick={handleProfileClick}>
                <p>{user.fullName}</p>
                <div className={[styles.iconContainer, user ? "" : styles.notLoggedIn].join(" ")}>
                  <i className="material-icons">person</i>
                </div>
              </div>
              :
              <Button
                onClick={handleProfileClick}
                variant="outlined"
                color="primary"
              >
                Regisztráció/Belépés
              </Button>
            }
          </div>
        </div>
      </div>

      <AuthModal handleClose={() => setIsAuthModalOpen(false)} open={isAuthModalOpen} />
      {/* <CraftsmanModal handleClose={() => setIsCraftsmanModalOpen(false)} open={isCraftsmanModalOpen} /> */}
      <SearchPopup onClose={() => setIsSearchModalOpen(false)} open={isSearchModalOpen} />
    </div>
  )
}

export default Header
