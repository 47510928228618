import axios from "axios"
import { APIBASEURL } from "../constants"
import { LoginApiResponse } from "../types"

export const loginGoogle = async (tokenID: string, persistent = false) => {
	const data = {
		tokenID,
		persistent,
	}

	const res = await axios({
		method: "post",
		url: APIBASEURL + "google-login",
		data,
		withCredentials: true,
	})

	return res.data as LoginApiResponse
}
