import axios from "axios"
import { APIBASEURL } from "../constants"
import { ApiResponse, MMEvent } from "../types"

export const getEvents = async ({
	numOfGuests = 0,
	endTime = null,
	startTime = null,
	experienceID,
}: {
	startTime?: number | null
	endTime?: number | null
	type?: string | null
	numOfGuests?: number
	experienceID: number
}) => {
	const res = await axios({
		method: "get",
		url: APIBASEURL + "experiences/" + experienceID + "/events",
		withCredentials: true,
		params: {
			startTime,
			endTime,
			numOfGuests,
		},
	})

	return res.data as ApiResponse<MMEvent[]>
}
