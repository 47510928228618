import axios from "axios"
import { APIBASEURL } from "../../constants"
import { ApiResponse, MMEvent, MMExperience } from '../../types';

export const getMasterExperiences = async (masterId: number) => {
	const res = await axios({
		method: "get",
		url: APIBASEURL + "master/" + masterId + "/experiences",
		withCredentials: true,
	})

	return res.data.data as MMExperience[]
}
