import axios from "axios"
import { APIBASEURL } from "src/constants"
import { ApiResponse, MMExperience } from "src/types"

export const getunVerifiedExperiencesAdmin = async () => {
  const res = await axios({
    method: "GET",
    url: APIBASEURL + "admin/experience-unverified",
    withCredentials: true,
  })

  return res.data as ApiResponse<MMExperience[]>
}
