import axios from "axios"
import { APIBASEURL } from "src/constants"
import { ApiResponse, MMEvent } from "src/types"

export const getExperienceEventsAdmin = async (experienceID: number) => {
  const res = await axios({
    method: "GET",
    url: APIBASEURL + `admin/experience/${experienceID}/events`,
    withCredentials: true,
  })

  return res.data as ApiResponse<{ past: MMEvent[]; future: MMEvent[] }>
}
