import React, { useState } from "react"
import { STATIC_URL } from "src/constants"
import styles from "./PrettyImage.module.scss"

interface Props {
  imageName: string
  className?: string
}

export const PrettyImage: React.FC<Props> = ({ imageName, className }) => {
  const [imageLoaded, setImageLoaded] = useState(false)

  return (
    <div className={className}>
      <img
        onLoad={() => setTimeout(() => setImageLoaded(true), 50 + Math.random() * 100)}
        src={STATIC_URL + imageName}
        className={styles.img}
      />
      <div className={`${styles.loader} ${imageLoaded ? styles.hidden : ""}`}>
        <img src={"/logo/logo-bird.svg"} className={styles.icon} />
      </div>
    </div>
  )
}
