import { useMediaQuery } from '@material-ui/core'
import React, { ReactNode, useEffect, useRef, useState } from 'react'
import styles from './ResponsiveCardContainer.module.scss'

const getNumOfChildren = () => {
  const container = document.getElementById('cont')
  if (!container) return 0
  const childrenWidth = parseInt(
    getComputedStyle(container).getPropertyValue('--responsiveChildWidth')
  )
  const containerWidth = container.offsetWidth
  const gap = parseInt(getComputedStyle(container).getPropertyValue('--gap'))

  let slots = Math.floor(containerWidth / childrenWidth)
  if (slots * childrenWidth + (slots - 1) * gap > containerWidth) {
    slots -= 1
  }

  if (slots >= 3) {
    return slots
  }

  return 4
}

const ResponsiveCardContainer: React.FC = ({ children }) => {
  const [numOfChildren, setNumOfChildren] = useState(4)

  const isMobile = useMediaQuery('(max-width: 600px)')

  useEffect(() => {
    let resizeTimeout: NodeJS.Timeout
    const setNumOfChildrenToState = () => {
      setNumOfChildren(getNumOfChildren())
    }
    const onResize = () => {
      resizeTimeout && clearTimeout(resizeTimeout)
      resizeTimeout = setTimeout(setNumOfChildrenToState, 100)
    }
    setNumOfChildrenToState()
    window.addEventListener('resize', onResize)
    return () => window.removeEventListener('resize', onResize)
  }, [])

  const childrenToShow: ReactNode[] = []
  React.Children.forEach(children, (child, index) => {
    if (index < numOfChildren) childrenToShow.push(child)
  })

  return (
    <div id='cont' className={isMobile ? styles.containerMobile : styles.containerDesktop}>
      {childrenToShow}
    </div>
  )
}
export default ResponsiveCardContainer
