import React, { useState, useEffect, useRef, ReactElement } from 'react';
import { useMediaQuery } from '@material-ui/core';
import { useHistory } from 'react-router';
import ExperienceDesktop from "../Experience/ExperienceDesktop"
import ExperienceMobile from "../Experience/ExperienceMobile"
import ExperienceCard from '../../common-components/ExperienceCard/ExperienceCard';
import ExperienceGalleryImage from "../Experience/ExperienceGalleryImage"
import styles from './Master.module.scss'
import MMButton from 'src/common-components/MMButton/MMButton';
import { Carousel } from 'react-responsive-carousel';
import ResponsiveCardContainer from 'src/common-components/ResponsiveCardContainer/ResponsiveCardContainer';
import { getFilteredExperiences } from 'src/apicalls/getFilteredExperiences';
import { getMaster } from '../../apicalls/Craftsman/getMaster';
import { useParams } from 'react-router-dom';
import { MMExperienceBasicData } from 'src/types';
import { STATIC_URL } from 'src/constants';
import { getMasterImages } from '../../apicalls/Craftsman/getMasterImages';
import ExperienceRatingsBlock from '../Experience/ExperienceRatingsBlock';
import { getMasterExperiences } from '../../apicalls/Craftsman/getMasterExperiences';
import { MMEvent, MMExperience } from '../../types';

const Master = () => {
  const fourImages = useMediaQuery("(min-width: 900px)")
  const fiveImages = useMediaQuery("(min-width: 1200px)")
  const isSmall = useMediaQuery("(max-width: 750px)")

  const carousel = useRef<Carousel>(null)
  const history = useHistory()

  const [images, setImages] = useState([])
  const [carouselElements, setCarouselElements] = useState<ReactElement[]>([])
  const [carouselVisible, setCarouselVisible] = useState(false)
  const [experiences, setExperiences] = useState<MMExperience[]>([])
  const [data, setData] = useState({
    fullName: "",
    city: "",
    description: "",
    profession: "",
  })
  const masterId = parseInt(useParams<{ masterId: string }>().masterId)

  useEffect(() => {
    const getImages = async () => {
      const rawImages = await getMasterImages(masterId);
      if (rawImages && rawImages && Array.isArray(rawImages)) {
        const elements= rawImages.map((img, index) => {
          return <img className={styles.carouselElement} key={index} src={STATIC_URL + img} />
        })
        if (elements) {
          setCarouselElements(elements);
        }
        if (rawImages) {
          const imagePaths = rawImages.map(im => im.filename)
          setImages(imagePaths as [])
        }
      }
    }
    getImages();

    const getData = async () => {
      const res = await getMaster(masterId)
      setData(res.data)
    }
    getData()

    const getExperiences = async () => {
      const res = await getMasterExperiences(masterId)
      setExperiences(res)
    }
    getExperiences()

  }, [])

  const handleThumbClick = (index: number) => {
    carousel.current?.moveTo(index)
    setCarouselVisible(true)
  }

  return (
    <div className={styles.masterContainer}>
      <div className={styles.infoSection}>
        <img
          className={styles.profilePicture}
          alt={data.fullName || ""}
          src={images[0] ? `${STATIC_URL}${images[0]}` : "/logo/logo-bird.svg"}
        />
        <div className={styles.textBox}>
          <h2 className={styles.masterTitle}>{data.fullName || ""}, {data.profession}</h2>
          <h4 className={styles.masterCity}>{data.city || ""}</h4>
          <p className={styles.masterDescription}>{data.description || ""}</p>
        </div>
      </div>
      <div className={styles.imageContainer}>
        {images[1] && <ExperienceGalleryImage
          imageName={images[1]}
          onClick={() => handleThumbClick(1)}
          isWithBorder
        />}
        {images[2] && <ExperienceGalleryImage
          imageName={images[2]}
          onClick={() => handleThumbClick(2)}
          isWithBorder
        />}

        {fourImages && (
          <ExperienceGalleryImage
            imageName={images[3]}
            onClick={() => handleThumbClick(3)}
            isWithBorder
          />
        )}
        {fiveImages && (
          <ExperienceGalleryImage
            imageName={images[4]}
            onClick={() => handleThumbClick(4)}
            isWithBorder
          />
        )}
        <MMButton
          handleClick={() => handleThumbClick(0)}
          className={styles.button}
          icon="collections"
        >
          Galéria
        </MMButton>
      </div>
      <div className={`${styles.popup} ${carouselVisible ? styles.visible : ""}`}>
        <i
          onClick={() => setCarouselVisible(false)}
          className={`material-icons ${styles.carouselCloseButton}`}
        >
          close
        </i>
        <div className={styles.carouselContainer}>
          <Carousel
            ref={carousel}
            swipeable={false}
            showThumbs={false}
            showStatus={false}
            showIndicators={false}
          >
            {carouselElements}
          </Carousel>
        </div>
      </div>
      <h4 className={[styles.masterExperiencesTitle, styles.masterTitle].join(" ")}>Mesterember programjai</h4>
      <ResponsiveCardContainer>
        {experiences?.map((experience, index) => (
          <ExperienceCard key={index} experience={experience} />
        ))}
      </ResponsiveCardContainer>
      <h4 className={[styles.masterExperiencesTitle, styles.masterTitle].join(" ")}>Mesterember értékelései</h4>
      <ExperienceRatingsBlock
        type="master"
      />
    </div>
  )
}

export default Master;
