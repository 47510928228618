import axios from 'axios'
import { APIBASEURL } from '../constants'
import { LoginApiResponse, LoginBody } from '../types'
import { hash } from '../utils/misc'

export const getUserData = async (data?: LoginBody) => {
  if (data) data.password = hash(data.password)
  const res = await axios({
    method: 'get',
    url: APIBASEURL + 'user-data',
    data,
    withCredentials: true,
  })

  return res.data as LoginApiResponse
}
