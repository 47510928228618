import axios from "axios"
import { APIBASEURL } from "src/constants"
import { ApiResponse } from "src/types"

export const deleteExperienceImageMasterAdmin = async (experienceImageID: number) => {
  const res = await axios({
    method: "DELETE",
    url: APIBASEURL + `master-admin/experience-image/${experienceImageID}`,
    withCredentials: true,
  })

  return res.data as ApiResponse<string>
}
