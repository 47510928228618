import React, { useContext } from "react"
import { useHistory } from "react-router-dom"
import { GlobalContext } from "../../../App"
import MMButton from "../../../common-components/MMButton/MMButton"
import MMModal from "../../../common-components/MMModal/MMModal"
import styles from "./SuccessfulOrderPopup.module.scss"

interface Props {}

const SuccessfulOrderPopup: React.FC<Props> = () => {
  const { user } = useContext(GlobalContext)
  const history = useHistory()

  const shouldShowSelf = history.location.hash === "#order-successful"

  const close = () => history.push(history.location.pathname)

  return (
    <MMModal open={shouldShowSelf} title="Sikeres rendelés" handleClose={close}>
      <div className={styles.content}>
        <h2 className={styles.subtitle}>Köszönjük a vásárlást</h2>
        <p>
          Egy megerősítő email fog érkezni a megadott email címre, amely tartalmazni fogja a
          rendelés részleteit.
        </p>
        <p>
          Felmerülő kérdésekkel forduljon hozzánk bizalommal az&nbsp;
          <a className={styles.mailLink} href="mailto:info@marminalunk.hu">
            info@marminalunk.hu
          </a>
          &nbsp;címen.
        </p>
        <div className={styles.buttonContainer}>
          {user && (
            <MMButton
              handleClick={() => history.push("/")}
              className={styles.toOrdersButton}
              theme="callToAction"
            >
              Rendeléseim
            </MMButton>
          )}
          <MMButton handleClick={close}>Bezárás</MMButton>
        </div>
      </div>
    </MMModal>
  )
}

export default SuccessfulOrderPopup
