import axios, { AxiosResponse } from "axios"
import { APIBASEURL } from "../constants"
import { ApiResponse, GuestCreationData, OrderCreationData } from "../types"

export const forgotPassword = async (
  email: string
) => {
  const res = await axios({
    method: "post",
    url: APIBASEURL + "new-password",
    data: {
      email
    },
  })

  return res as AxiosResponse<ApiResponse<string>>
}
