import axios from "axios"
import { APIBASEURL } from "src/constants"
import { ApiResponse, OrderAdmin } from "src/types"

export const downloadEventOrdersAdmin = async (eventID: number) => {
  const res = await axios({
    method: "GET",
    url: APIBASEURL + `admin/event/${eventID}/orders`,
    withCredentials: true,
  })
  const fileURL = window.URL.createObjectURL(new Blob([res.data]))
  const link = document.createElement("a")
  link.href = fileURL
  link.setAttribute("download", `mm-order-export-${eventID}.csv`)
  link.style.display = "none"
  document.body.appendChild(link)
  link.click()
  link.remove()
  window.URL.revokeObjectURL(fileURL)
}
