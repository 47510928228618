import { FormGroup, FormControlLabel, Checkbox } from '@material-ui/core'
import { format } from "date-fns"
import { hu } from "date-fns/locale"
import React, { useContext, useState } from "react"
import { GlobalContext } from "../../../App"
import MMButton from "../../../common-components/MMButton/MMButton"
import MMModal from 'src/common-components/MMModal/MMModal'
import { MMEvent } from "../../../types"
import FinishOrderWrapper from "./FinishOrder/FinishOrderWrapper"
import styles from "./OrderSummary.module.scss"

interface Props {
  setTransformed?: (val: boolean) => void
  selectedEvent: MMEvent | null
  price: number
  isOpen: boolean
  onFinished: () => void
}

const OrderSummary: React.FC<Props> = ({
  setTransformed,
  selectedEvent,
  price,
  isOpen,
  onFinished,
}) => {
  const { numOfGuests } = useContext(GlobalContext)
  const [finishPopupOpen, setFinishPopupOpen] = useState(false)
  const [isASZFChecked, setIsASZFChecked] = useState(false);
  const [aszfModal, setAszfModal] = useState(false);

  if (!selectedEvent) {
    setTransformed?.(false)
    return null
  }

  const checkASZFIsChecked = () => {
    if (isASZFChecked) {
      return true;
    }
    setAszfModal(true)
    return false
  }

  return (
    <div className={styles.container}>

      <MMModal
        handleClose={() => { setAszfModal(false) }}
        children={<p>Kérlek elösszőr fogadd el a felhasználási feltételeket!</p>}
        title="Figyelem"
        open={aszfModal}
      />
      <div className={styles.title}>
        <div className={styles.top}>
          <p>Foglalás</p>
        </div>
      </div>
      <div className={styles.dataBlock}>
        <p className={styles.point}>Választott időpont:</p>
        <p className={styles.date}>
          {format(new Date(selectedEvent.startTime * 1000), "PPpp", { locale: hu })}
        </p>
      </div>
      <div className={styles.dataBlock}>
        <span className={styles.point}>Vendégek száma:</span> <span>{numOfGuests} fő</span>
      </div>
      <div className={styles.dataBlock}>
        <span className={styles.point}>Fizetendő összeg:</span>{" "}
        <span>{(numOfGuests || 0) * price} Ft</span>
      </div>
      {isOpen ? (
        <div className={styles.info}>
          <p>Információ</p>
          <p>
            A foglalás után e-mailben elküldjük a fizetéshez, illetve a programhoz kapcsolódó egyéb
            részleteket. Az e-mailben található linkre kattintva lehet véglegesíteni a foglalást, a
            rendszerbe csak ekkor kerül be. <br />
            <br /> Felmerülő kérdésekkel vagy problémákkal kapcsolatban az{" "}
            <a>info@marminalunk.hu</a> email címen vagyunk elérhetőek.
          </p>
        </div>
      ) : null}
      <div className={styles.buttons}>

        <FormGroup>
          <FormControlLabel
            control={<Checkbox
              color="primary"
              checked={isASZFChecked}
              onChange={(e) => {setIsASZFChecked(e.target.checked)}}
            />}
            label={<div>
              Elfogadom az <a href="/aszf" target="_blank">Általános Szerződési Feltételeket</a> és az <a href="/adatkezelesi" target="_blank">Adatkezelési nyilatkozatot</a>
            </div>}
          />
        </FormGroup>
        <MMButton theme="callToAction" handleClick={() => {if(checkASZFIsChecked()) setFinishPopupOpen(true)}}>
          Fizetés
        </MMButton>
        <MMButton handleClick={() => setTransformed?.(false)}>Vissza</MMButton>
      </div>
      <FinishOrderWrapper
        onFinished={onFinished}
        eventID={selectedEvent.id}
        open={finishPopupOpen}
        setOpen={setFinishPopupOpen}
      />
    </div>
  )
}
export default OrderSummary
