import React from "react"
import MMCheckbox from "../../../../common-components/MMCheckbox/MMCheckbox"
import MMInput from "../../../../common-components/MMInput/MMInput"
import styles from "./BillingInformation.module.scss"

interface Props {
	fullName: string
	city: string
	zipCode: string
	addressLine1: string
	addressLine2: string
	setFullName: (val: string) => void
	setCity: (val: string) => void
	setZipCode: (val: string) => void
	setAddressLine1: (val: string) => void
	setAddressLine2: (val: string) => void
	errors?: { [key: string]: boolean } | null
	isCompany: boolean
	setIsCompany: (v: boolean) => void
	companyName: string
	setCompanyName: (v: string) => void
	vatNumber: string
	setVatNumber: (v: string) => void
	contactName: string
	setContactName: (v: string) => void
}

const BillingInformation: React.FC<Props> = ({
	zipCode,
	setZipCode,
	setFullName,
	setCity,
	setAddressLine2,
	addressLine1,
	addressLine2,
	city,
	fullName,
	setAddressLine1,
	errors,
	isCompany,
	setIsCompany,
	companyName,
	setCompanyName,
	setVatNumber,
	vatNumber,
	contactName,
	setContactName,
}) => {
	const errorText = "Szükséges mező"

	return (
		<>
			<MMCheckbox value={isCompany} setValue={setIsCompany} label="Cég / egyéni vállalkozó" />
			{!isCompany ? <MMInput label={"Teljes név"} value={fullName} setValue={setFullName} /> : null}
			{isCompany ? (
				<>
					<MMInput label="Cégnév" value={companyName} setValue={setCompanyName} />
					<MMInput label="Adószám" value={vatNumber} setValue={setVatNumber} />
					<MMInput label="Kapcsolattartó neve" value={contactName} setValue={setContactName} />
				</>
			) : null}
			<div className={styles.rowFlex}>
				<MMInput
					errorText={errors?.["city"] ? errorText : undefined}
					label="Város"
					value={city}
					setValue={setCity}
				/>
				<MMInput
					errorText={errors?.["zipCode"] ? errorText : undefined}
					label="Irányítószám"
					value={zipCode}
					setValue={setZipCode}
				/>
			</div>
			<MMInput
				errorText={errors?.["addressLine1"] ? errorText : undefined}
				label="Utca, házszám"
				value={addressLine1}
				setValue={setAddressLine1}
			/>
			<MMInput
				errorText={errors?.["addressLine2"] ? errorText : undefined}
				label="Épület, emelet, ajtó"
				value={addressLine2}
				setValue={setAddressLine2}
			/>
		</>
	)
}
export default BillingInformation
