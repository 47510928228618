import axios from "axios"
import { APIBASEURL } from "src/constants"
import { ApiResponse } from "src/types"

export const deleteExperienceAdmin = async (experienceID: number) => {
  const res = await axios({
    method: "DELETE",
    url: APIBASEURL + `admin/experience/${experienceID}`,
    withCredentials: true,
  })

  return res.data as ApiResponse<string>
}
