import React, { useContext, useEffect, useState } from "react"
import { GlobalContext } from "../../App"
import { useHistory } from "react-router"
import { MMExperience } from "../../types"
import { saveExperience } from "../../apicalls/saveExperience"
import styles from "./ExperienceTitle.module.scss"

interface Props {
  experience: MMExperience
}

const ExperienceTitle: React.FC<Props> = ({ experience }) => {
  const history = useHistory()
  const { user, updateUser } = useContext(GlobalContext)
  const [saved, setSaved] = useState<boolean | null>(null)

  useEffect(() => {
    if (user?.savedExperiences) {
      const experienceSaved = user.savedExperiences.find((e) => e.id == experience.id) !== undefined
      setSaved(experienceSaved)
    } else {
      setSaved(null)
    }
  }, [user?.savedExperiences])

  const handleSave = async () => {
    const res = await saveExperience(experience.id)
    if (res.result === "ok") {
      setSaved(!saved)
      updateUser()
    } else {
      console.log("Failed to update experience save status:", res.data)
    }
  }

  const handleBackButton = () => {
    if (history.length === 1) {
      history.replace("/")
    } else {
      history.goBack()
    }
  }

  return (
    <div className={styles.container}>
      <i className="material-icons" onClick={handleBackButton}>
        arrow_back
      </i>
      <div className={styles.data}>
        <p className={styles.title}>
          {experience.name}{" "}
          {saved !== null ? (
            <i
              className={`material-icons ${styles.saveButton} ${saved ? styles.saved : ""}`}
              onClick={handleSave}
            >
              {saved ? "favorite" : "favorite_border"}
            </i>
          ) : null}
        </p>
        <div className={styles.info}>
          {experience.likeNumber ? (
            <div className={styles.rating}>
              <i className="material-icons" style={{color: "#5D6146"}}>favorite</i>
              <p>{experience.likeNumber}</p>
              <p>&mdash;</p>
            </div>
          ) : null}
          <p> {experience.city}</p>
        </div>
      </div>
    </div>
  )
}
export default ExperienceTitle
