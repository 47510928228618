import axios from "axios"
import { APIBASEURL } from "src/constants"
import { ApiResponse, MMEvent } from "src/types"

export const deleteEventMasterAdmin = async (event: MMEvent) => {
  const res = await axios({
    method: "DELETE",
    url: APIBASEURL + `master-admin/event/${event.id}`,
    data: event,
    withCredentials: true,
  })

  return res.data as ApiResponse<string>
}
