import axios from "axios"
import { APIBASEURL } from "src/constants"
import { ApiResponse, MMExperience } from "src/types"
interface experienceVerifiedList {
  verified: MMExperience[],
  unverified: MMExperience[]
}

export const setExperienceToVerifiedAdmin = async (id: number) => {
  const res = await axios({
    method: "POST",
    url: APIBASEURL + `admin/experience/${id}/verify`,
    withCredentials: true,
  })
  return res.data.data as experienceVerifiedList
}
