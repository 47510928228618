import React, { useEffect, useState } from "react"
import { loginFacebook } from "../../apicalls/loginFacebook"
import Loader from "../../common-components/Loader/Loader"
import styles from "./FBAuth.module.css"
const FBAuth: React.FC = () => {
	const [status, setStatus] = useState<"loading" | "error" | "success">("loading")

	useEffect(() => {
		const token = new URLSearchParams(window.location.search).get("code")
		if (!token) {
			setStatus("error")
			return
		}
		//;(window as any).facebookMMLoginCallback(token)
		window.opener.fbMMLoginCallback(token)
		window.close()
	}, [])

	return null
}
export default FBAuth
