import { useMediaQuery } from '@material-ui/core'
import React, { useContext, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { GlobalContext } from '../../App'
import Loader from '../../common-components/Loader/Loader'
import { usePastEvents } from '../../utils/hooks'
import PastEvent from './PastEvent'
import ProfileDesktop from './ProfileDesktop'
import ProfileMobile from './ProfileMobile'

const ProfileWrapper: React.FC = () => {
  const isSmall = useMediaQuery('(max-width: 950px)')
  const { appLoaded, user } = useContext(GlobalContext)
  const history = useHistory()

  useEffect(() => {
    if (user === null && appLoaded) {
      history.replace('/')
      return
    }
  }, [user])

  const [pastEvents, updatePastEvents] = usePastEvents()

  if (!pastEvents) return <Loader width='5rem' />

  const pastEventElements = pastEvents.map((e, i) => (
    <PastEvent {...e} updatePastEvents={updatePastEvents} key={i} />
  ))

  return isSmall ? (
    <ProfileMobile pastEvents={pastEventElements} />
  ) : (
    <ProfileDesktop pastEvents={pastEventElements} />
  )
}
export default ProfileWrapper
