import React, { useState } from "react"
import EllipseSelect from "../../common-components/EllipseSelect/EllipseSelect"
import SlideLayout from "../../common-components/SlideLayout/SlideLayout"
import Preferences from "./Preferences"
import styles from "./ProfileMobile.module.scss"
import { PastEventData } from "../../types"
import PastEvent from "./PastEvent"

interface Props {
	pastEvents: JSX.Element[]
}

const ProfileMobile: React.FC<Props> = ({ pastEvents }) => {
	const [transformed, setTransformed] = useState(false)

	return (
		<div className={styles.container}>
			<EllipseSelect
				action1={() => setTransformed(false)}
				action2={() => setTransformed(true)}
				label1="Profil adatok"
				label2="Korábbi élmények"
				transformed={transformed}
			/>
			<SlideLayout customTransformed={transformed}>
				<div className={styles.prefContainer}>
					<Preferences />
				</div>
				<div className={styles.eventsContainer}>{pastEvents}</div>
			</SlideLayout>
		</div>
	)
}
export default ProfileMobile
