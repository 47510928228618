import React from "react"
import { useHistory } from "react-router"
import styles from "./ExperienceInfos.module.scss"
import { MMExperience } from "../../types"
import { addressToString } from "../../utils/misc"

interface Props {
  experience: MMExperience
}

const ExperienceInfos: React.FC<Props> = ({ experience }) => {
  const history = useHistory()

  const handleMasterClick = () => {
    if (experience.masterId) {
      history.push({ pathname: "/master/" + experience.masterId })
    } else {
      return false;
    }
  }

  return (
    <div className={styles.container}>
      <div className={styles.block}>
        <p className={styles.title}>Főbb tudnivalók</p>
        <div>
          <i className="material-icons">groups</i>
          <p>Legfeljebb {experience.defaultMaxPeople} fő (minimum létszám: {experience?.defaultMinPeople})</p>
        </div>
        <div className={experience.masterId ? styles.masterName : ""} onClick={handleMasterClick}>
          <i className="material-icons">emoji_people</i>
          <p>{experience.conductorName} vezetésével</p>
        </div>
        <div className={experience.masterId ? styles.masterName : ""} onClick={handleMasterClick}>
          <i className="material-icons">label</i>
          <p>Tematika: {experience.type}</p>
        </div>
        <div>
          <i className="material-icons">place</i>
          <p>
            {addressToString({
              addressLine1: experience.addressLine1,
              city: experience.city,
              zipCode: experience.zipCode.toString(),
              addressLine2: experience.addressLine2,
            })}
          </p>
        </div>
        <div>
          <i className="material-icons">payments</i>
          <p className={[experience.originalPrice !== null ? styles.sale : null].join(" ")}>
            {experience.price} Ft / fő
          </p>
          {experience.originalPrice !== null ? (
            <p className={styles.originalPrice}>{experience.originalPrice} Ft / fő</p>
          ) : null}
        </div>
      </div>
      <div className={styles.block}>
        <p className={styles.title}>Programismertető</p>
        {experience.description.map((d, i) => (
          <p className={styles.description} key={i}>
            {d}
            <br />
          </p>
        ))}
      </div>
      <div className={styles.block}>
        <p className={styles.title}>Mire lesz szükség</p>
        <ul className={styles.list}>
          {experience.equipment.map((d, i) => (
            <li key={i}>{d}</li>
          ))}
        </ul>
      </div>
    </div>
  )
}
export default ExperienceInfos
