import axios from "axios"
import { APIBASEURL } from "src/constants"
import { ApiResponse, MMEvent } from "src/types"

export const getFinancialInfo = async () => {
  const res = await axios({
    method: "GET",
    url: APIBASEURL + `/master-admin/payment-info`,
    withCredentials: true,
  })

  return res.data
}
