import React, { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { getFilteredExperiences } from '../../apicalls/getFilteredExperiences'
import { GlobalContext } from '../../App'
import ExperienceCard from '../../common-components/ExperienceCard/ExperienceCard'
import Loader from '../../common-components/Loader/Loader'
import MMButton from '../../common-components/MMButton/MMButton'
import MMSelect from '../../common-components/MMSelect/MMSelect'
import { MMExperienceBasicData, SelectOptions } from '../../types'
import styles from './Listing.module.scss'

type sortType = 'price' | 'score'

const sortOptions: SelectOptions<sortType> = [
  {
    value: 'price',
    label: 'Ár',
  },
  {
    value: 'score',
    label: 'Értékelés',
  },
]
type T = MMExperienceBasicData

const createSortFunction = (sortBy: sortType) => {
  if (sortBy === 'price') return (prevE: T, e: T) => prevE.price - e.price
  else return (prevE: T, e: T) => prevE.avgScore - e.avgScore
}

const sortExperiences = (
  sortBy: sortType,
  experiences: MMExperienceBasicData[] | null
) => {
  if (!experiences) return null
  const sortFC = createSortFunction(sortBy)
  experiences.sort(sortFC)
  if (sortBy === 'score') {
    experiences.reverse()
  }
  return experiences
}

const Listing: React.FC = () => {
  const { numOfGuests, searchStart, searchEnd, filterRadius, filterLocation } =
    useContext(GlobalContext)

  const [experiences, setExperiences] =
    useState<MMExperienceBasicData[] | null>(null)
  const [sortBy, setSortBy] = useState<sortType>('score')
  const history = useHistory()

  useEffect(() => {
    const setEventsToState = async () => {
      const res = await getFilteredExperiences({
        endTime: searchEnd ? Math.round(searchEnd.getTime() / 1000) : null,
        startTime: searchStart
          ? Math.round(searchStart.getTime() / 1000)
          : null,
        numOfGuests: numOfGuests || 0,
        filterLocation: filterLocation || null,
        filterRadius: filterRadius || null,
      })
      setExperiences(sortExperiences(sortBy, res.data))
    }
    setEventsToState()
  }, [numOfGuests, searchEnd, searchStart, filterLocation, filterRadius])

  useEffect(() => {
    const newList = experiences
      ? [...sortExperiences(sortBy, experiences)!]
      : null
    setExperiences(newList)
  }, [sortBy])

  function handleBackButton() {
    history.push('/')
  }

  if (!experiences) return <Loader width='5rem' />

  if (experiences.length === 0)
    return (
      <div className={styles.notFound}>
        A megadott kritériumokkal sajnos egy programot sem találtunk.
        <MMButton className={styles.backButton} handleClick={handleBackButton}>
          Vissza a főoldalra
        </MMButton>
      </div>
    )

  const experienceCards = experiences.map((e, index) => {
    return (
      <div className={styles.cardContainer} key={index}>
        <ExperienceCard experience={e} />
      </div>
    )
  })

  console.log(experiences)

  return (
    <div className={styles.container}>
      <div className={styles.selectContainer}>
        <p>Rendezés</p>
        <MMSelect
          label='Rendezés'
          value={sortBy}
          setValue={setSortBy}
          options={sortOptions}
        />
      </div>
      <p className={styles.title}>
        <i
          onClick={handleBackButton}
          className={`material-icons ${styles.backButton}`}
        >
          arrow_back
        </i>
        {experiences.length} programot találtunk
      </p>
      <div className={styles.list}>{experienceCards}</div>
    </div>
  )
}
export default Listing
