import axios, { AxiosResponse } from "axios"
import { APIBASEURL } from "../../constants"
import { ApiResponse, ExperienceImage, ExperienceImageMetadata} from "../../types"

export const getMasterImages = async (masterId: number) => {
	const res = await axios({
		method: "get",
		url: APIBASEURL + `master/${masterId}/images`,
		withCredentials: true,
	})
  return res.data.data as ExperienceImageMetadata[]
}
