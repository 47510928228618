import axios, { AxiosResponse } from "axios"
import { APIBASEURL } from "../../constants"
import { ApiResponse, PastEventData, Rating } from "../../types"

export const getMasterRatings = async (masterId: number) => {
	try {
		const res = await axios({
			method: "get",
			url: APIBASEURL + "master/" + masterId + "/ratings",
			withCredentials: true,
		})
		return res.data.data.ratings as Rating[]
	} catch {
		return false
	}
}
