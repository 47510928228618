import React, { useState, useEffect, useContext } from "react"
import styles from "./ExperienceImageEditor.module.scss"
import { MMExperience, MMEvent, ExperienceImage, ExperienceImageMetadata } from "src/types"
import MMButton from "src/common-components/MMButton/MMButton"
import { GlobalContext } from "src/App"
import { PrettyImage } from "src/common-components/PrettyImage/PrettyImage"
import { getExperienceImagesAdmin } from "src/apicalls/admin/getExperienceImagesAdmin"
import { deleteExperienceImageAdmin } from "src/apicalls/admin/deleteExperienceImageAdmin"
import { createExperienceImageAdmin } from "src/apicalls/admin/createExperienceImageAdmin"
import { useMediaQuery } from "@material-ui/core"
import { getMasterImagesAdmin } from '../../apicalls/CraftsmanAdmin/getMasterImagesAdmin';
import { deleteMasterImageAdmin } from '../../apicalls/CraftsmanAdmin/deleteMasterImageAdmin';
import { createMasterImageAdmin } from '../../apicalls/CraftsmanAdmin/createMasterImageAdmin';
import { createExperienceImageMasterAdmin } from '../../apicalls/CraftsmanAdmin/createExperienceImageMasterAdmin';
import { getExperienceImagesMasterAdmin } from '../../apicalls/CraftsmanAdmin/getExperienceImagesMasterAdmin';
import { deleteExperienceImageMasterAdmin } from '../../apicalls/CraftsmanAdmin/deleteExperienceImageMasterAdmin';

interface Props {
  type: 'experience' | 'master'
  experience?: MMExperience
  loadImages: boolean
  master?: any
}

const ExperienceImageEditor: React.FC<Props> = ({ type, experience = 'experience', loadImages, master}) => {
  const { setConfirmModal } = useContext(GlobalContext)
  const [images, setImages] = useState<ExperienceImage[]>([])
  const isLarge = useMediaQuery("(min-width: 1900px)")
  const { user } = useContext(GlobalContext)

  const allowedFileExtensions = ".jpg,.jpeg,.png"
  const baseObject = type === 'experience' ? experience : master

  useEffect(() => {
    if (loadImages && images.length === 0) {
      loadImageList()
    }
  }, [loadImages])

  async function loadImageList() {
    let r;
    if (type === 'experience') {
      if (user?.isMaster) {
        r = await getExperienceImagesMasterAdmin(baseObject.id)
      } else {
        r = await getExperienceImagesAdmin(baseObject.id)
      }
      if (r.result === "ok") setImages(r.data)
    } else {
      const r = await getMasterImagesAdmin(baseObject.id)
      if (r.result === "ok") setImages(r.data)
    }
  }

  async function deleteImage(id: number) {
    let r;
    if (type === 'experience') {
      if (user?.isMaster) {
        r = await deleteExperienceImageMasterAdmin(id)
      } else {
        r = await deleteExperienceImageAdmin(id)
      }
      if (r.result === "ok") loadImageList()
    } else {
      const r = await deleteMasterImageAdmin(id)
      if (r.result === "ok") loadImageList()
    }
  }

  function onDelete(img: ExperienceImage) {
    setConfirmModal({
      open: true,
      title: "Kép törlése",
      text: `Biztosan végleg törölni kívánja a következő képet: '${img.filename}'?`,
      cancelLabel: "Mégse",
      actionLabel: "Törlés",
      destructive: true,
      action: () => deleteImage(img.id),
    })
  }

  function onUploadClick() {
    document.getElementById("mm-admin-image-upload" + baseObject.id)?.click()
  }

  async function onUploadFileSelected(file: File) {
    if (!file) return

    // TODO: No need for this as server side compression is now live
    // if (file.size > 2 * 1024 * 1024) {
    //   alert("Túl nagy fájl (>1MB)")
    //   return
    // }

    const metadata: ExperienceImageMetadata = {
      filename: file.name,
      main: images.length === 0,
      ordinalNumber: images.length,
      description: null,
    }
    if (type === 'experience') {
      if (user?.isMaster) {
        const r = await createExperienceImageMasterAdmin(baseObject.id, metadata, file)
        if (r.result === "ok") loadImageList()
      } else {
        const r = await createExperienceImageAdmin(baseObject.id, metadata, file)
        if (r.result === "ok") loadImageList()
      }
    } else {
      const r = await createMasterImageAdmin(metadata, file)
      if (r.result === "ok") loadImageList()
    }
  }

  return (
    <div className={styles.content}>
      <h1 className={styles.blockTitle}>Képek</h1>
      <div className={styles.block}>
        {type === "master" && <p className={styles.masterHint}>Az Ön által elsőnek feltöltött kép lesz a Ön kézműves profilképe. Az ezt követően feltöltött képek a kézműves profil képtárában lesznek elérhetőek</p>}
        {images.map((img, idx) => (
          <div
            key={"img-" + idx}
            className={[styles.imageWrapper, isLarge ? styles.large : null].join(" ")}
          >
            <PrettyImage imageName={img.filename} className={styles.image} />
            <div onClick={() => onDelete(img)} className={[styles.button, styles.delete].join("")}>
              <i className="material-icons">close</i>
            </div>
          </div>
        ))}

        <div
          onClick={onUploadClick}
          className={[styles.imageWrapper, isLarge ? styles.large : null].join(" ")}
        >
          <input
            type="file"
            accept={allowedFileExtensions}
            id={"mm-admin-image-upload" + baseObject.id}
            style={{ display: "none" }}
            onChange={(e) => {
              if (e.target.files) onUploadFileSelected(e.target.files[0])
            }}
          />
          <div className={styles.uploadButton}>
            <i className="material-icons">add</i>
          </div>
        </div>
        <div className={styles.filler} />
      </div>
    </div>
  )
}

export default ExperienceImageEditor
