import React, { useContext, useState } from "react"
import { GlobalContext } from "../../../../App"
import MMButton from "../../../../common-components/MMButton/MMButton"
import MMModal from "../../../../common-components/MMModal/MMModal"
import { Address } from "../../../../types"
import { useBillingFields } from "../../../../utils/hooks"
import BillingInformation from "./BillingInformation"
import styles from "./FinishOrderLoggedIn.module.scss"
import { handeToPaymentClick, makeCreateOrderPayload } from "./finishOrderUtils"

type NecessaryFields = keyof Address | "fullName"

const validate = (values: { [key: string]: any }) => {
  const necessaryFields: NecessaryFields[] = ["city", "zipCode", "addressLine1", "fullName"]
  const errors = {} as { [key in NecessaryFields]: boolean }
  necessaryFields.forEach((name) => {
    if (!values[name]) errors[name] = true
  })
  return errors
}

interface Props {
  open: boolean
  setOpen: (val: boolean) => void
  eventID: number
}

const FinishOrderLoggedIn: React.FC<Props> = ({ open, setOpen, eventID }) => {
  const { user, numOfGuests } = useContext(GlobalContext)

  const [errors, setErrors] = useState<{ [key in NecessaryFields]: boolean } | null>(null)

  const billingProps = useBillingFields(user, errors)

  const values = makeCreateOrderPayload({ eventID, billingProps, numOfGuests: numOfGuests || 1 })

  const onSubmit = () => handeToPaymentClick(setErrors, validate, values)

  return (
    <MMModal open={open} handleClose={() => setOpen(false)} title="Utolsó lépések">
      <div className={styles.inputBlock}>
        <BillingInformation {...billingProps} />
        <MMButton handleClick={onSubmit} theme="callToAction">
          Foglalás
        </MMButton>
      </div>
    </MMModal>
  )
}
export default FinishOrderLoggedIn
