import { useMediaQuery } from "@material-ui/core"
import React, { useEffect, useState } from "react"
import Helmet from "react-helmet"
import { useParams, useHistory } from "react-router-dom"
import { getExperience } from "../../apicalls/getExperience"
import Loader from "../../common-components/Loader/Loader"
import { MMExperience } from "../../types"
import ExperienceDesktop from "./ExperienceDesktop"
import ExperienceMobile from "./ExperienceMobile"
import SuccessfulOrderPopup from "./Order/SuccessfulOrderPopup"

export const ExperienceContext = React.createContext({
  updateExperience: () => {},
})

const ExperienceWrapper: React.FC = () => {
  const isSmall = useMediaQuery("(max-width: 750px)")
  const [experience, setExperience] = useState<MMExperience | null>(null)
  const history = useHistory()

  const experienceID = parseInt(useParams<{ experienceId: string }>().experienceId)

  const updateExperience = async () => {
    if (isNaN(experienceID)) {
      // Handle non-number experienceID
      history.replace("/")
    }
    const res = await getExperience(experienceID)
    if (res.result === "ok") {
      setExperience(res.data)
    } else {
      // Handle non-existent experience ID
      history.replace("/")
    }
  }

  useEffect(() => {
    updateExperience()
  }, [experienceID])

  if (!experience) return <Loader width="5rem" />

  return (
    <>
      <SuccessfulOrderPopup /> {/* conditional display logic is handled in the compmonent itself */}
      <ExperienceContext.Provider value={{ updateExperience }}>
        <Helmet>
          <title>{experience.name} | Márminálunk</title>
        </Helmet>
        {isSmall ? (
          <ExperienceMobile experience={experience} />
        ) : (
          <ExperienceDesktop experience={experience} />
        )}
      </ExperienceContext.Provider>
    </>
  )
}
export default ExperienceWrapper
