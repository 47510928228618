import axios from "axios"
import { APIBASEURL } from "src/constants"
import { ApiResponse, MMExperience } from "src/types"

export const createExperienceAdmin = async (masterId: number) => {
  const res = await axios({
    method: "POST",
    url: APIBASEURL + `master-admin/experience/${masterId}`,
  })

  return res.data as ApiResponse<MMExperience[]>
}
