import React, {useState, useEffect} from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import styles from './FinancialTable.module.scss'
import { getFinancialInfo } from '../../apicalls/CraftsmanAdmin/getFinancialInfo';


const FinancialTable = () => {

  const [numbers, setNumbers] = useState<any[]>([]);
  const [activePeriod, setActivePeriod] = useState(0)

  useEffect(() => {
    async function getPaymentInfo() {
      const res = await getFinancialInfo()
      setNumbers(res.data)
      setActivePeriod(res.data.length - 1)
    }
    getPaymentInfo()
  }, []);

  const swipe = (direction: string) => {
    if (direction === 'left') {
      setActivePeriod(prev => --prev)
    } else if (direction === 'right') {
      setActivePeriod(prev => ++prev)
    }
  }

  const getDeadLineDate = (date: string) => {
    const year = new Date().getFullYear()
    const [month, days] = date.split("/")
    const [day1, day2] = days.split('-')
    let res = new Date(year+' '+month+' '+day2)
    const res2 = res.setDate(res.getDate() + 1);
    return new Date(res2).toLocaleDateString('hu')
  }

  function getMonthFromString(mon: string){
    return new Date(Date.parse(mon +" 1, 2012")).getMonth()+1
  }

  const getPeriodDate = (date: string) => {
    const year = new Date().getFullYear()
    const [month, days] = date.split("/")
    const [day1, day2] = days.split('-')
    return `${year}. ${getMonthFromString(month)}. ${day1} - ${getMonthFromString(month)}. ${day2}`
  }

  return (
    numbers.length > 0 ? (
      <TableContainer className={styles.table} component={Paper}>
        {activePeriod > 0 &&
          <div className={styles.left} onClick={() => swipe('left')}><ChevronLeftIcon sx={{ fontSize: 40 }} /></div>}
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Fizetési periódus</TableCell>
              <TableCell>
                {getPeriodDate(numbers[activePeriod][0])}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>Fizetési határidők</TableCell>
              <TableCell>{getDeadLineDate(numbers[activePeriod][0])}</TableCell>
            </TableRow>
            {numbers[activePeriod][1] && Object.keys(numbers[activePeriod][1]).map(function (key) {
              if (key !== "sum" && key !== "sumNet") {
                return (
                  <TableRow>
                    <TableCell>{key}</TableCell>
                    <TableCell>{numbers[activePeriod][1][key].price && numbers[activePeriod][1][key].price}</TableCell>
                  </TableRow>
                )
              }
            })}
            <TableRow>
              <TableCell>Beérkező összeg</TableCell>
              <TableCell>{numbers[activePeriod][1]["sum"]}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Jutalékkal csökkentett összeg</TableCell>
              <TableCell>{numbers[activePeriod][1]["sumNet"]}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
        {activePeriod < numbers.length - 1 &&
          <div className={styles.right} onClick={() => swipe('right')}><ChevronRightIcon sx={{ fontSize: 40 }} /></div>}
      </TableContainer>
    )
      :
      <></>
  );
}

export default FinancialTable;
