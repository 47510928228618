import axios from "axios"
import { APIBASEURL } from "../constants"
import { ApiResponse, MMEvent } from "../types"

export const refundEvent = async (
	orderId: number,
) => {
	const res = await axios({
		method: "post",
		url: APIBASEURL + "refund-order/" + orderId,
		withCredentials: true,
	})

	return res.data as ApiResponse<MMEvent[]>
}
