import React, { useContext, useEffect, useState } from "react"
import { MMEvent, MMExperience } from "src/types"
import MMButton from "src/common-components/MMButton/MMButton"
import MMInput from "src/common-components/MMInput/MMInput"
import EventDisplay from "./EventDisplay"
import styles from "./ExperienceDataForm.module.scss"
import { getExperienceEventsAdmin } from "src/apicalls/admin/getExperienceEventsAdmin"
import CreateEventView from "./CreateEventView"
import { GlobalContext } from 'src/App'
import { getExperienceEventsMasterAdmin } from '../../apicalls/CraftsmanAdmin/getExperienceEventsMasterAdmin';

const handleNaNFormValue = (v: string) => (isNaN(parseInt(v)) ? 0 : parseInt(v))
const handleNaNFormValueFloat = (v: string) => (isNaN(parseFloat(v)) ? 0 : parseFloat(v))
const handleIntFormValueDisplay = (i: number) => (i ? i.toString() : "")

const sanitizeFloat = (v: string) => {
  v = v.replace(/,/g, ".").replace(/[^0-9.]/g, "") // keep only valid chars, replace ',' with '.'
  if (v.indexOf(".") !== v.lastIndexOf(".")) v = v.substring(0, v.lastIndexOf(".")) // keep only the 1st '.'
  return v
}

interface ResettableInputProps {
  label: string
  value: string
  onChange: (v: string) => void
  onReset: () => void
  small?: boolean
  dirty?: boolean
  rows?: number
}

const ResettableInput: React.FC<ResettableInputProps> = ({
  label,
  value,
  onChange,
  onReset,
  small,
  dirty,
  rows,
}) => {
  return (
    <span className={`${styles.resettable} ${small ? styles.smaller : null}`}>
      <MMInput
        rows={rows}
        className={styles.input}
        label={label}
        value={value}
        setValue={onChange}
      />
      <MMButton disabled={!dirty} handleClick={onReset}>
        <i className="material-icons">refresh</i>
      </MMButton>
    </span>
  )
}

interface Props {
  experience: MMExperience
  formState: MMExperience
  loadEvents: boolean
  setFormState: (state: MMExperience) => void
}

export const ExperienceDataForm: React.FC<Props> = ({
  experience,
  formState,
  loadEvents,
  setFormState,
}) => {
  const [events, setEvents] = useState<{ past: MMEvent[]; future: MMEvent[] }>({
    past: [],
    future: [],
  })
  const [durationString, setDurationString] = useState(experience.lengthInHours.toFixed(1))
  const { user } = useContext(GlobalContext)

  useEffect(() => {
    if (loadEvents) updateEventList()
  }, [loadEvents])

  useEffect(() => {
    setFormState({ ...formState, lengthInHours: handleNaNFormValueFloat(durationString) })
  }, [durationString])

  async function updateEventList() {
    let r;
    if (user?.isMaster) {
      r = await getExperienceEventsMasterAdmin(experience.id)
    } else {
      r = await getExperienceEventsAdmin(experience.id)
    }
    if (r.result === "ok") setEvents(r.data)
  }

  return (
    <>
      <div className={styles.block}>
        <h1 className={styles.blockTitle}>Alapadatok</h1>
        <span className={styles.row}>
          <ResettableInput
            label="Program neve"
            value={formState.name}
            onChange={(v) => setFormState({ ...formState, name: v })}
            onReset={() => setFormState({ ...formState, name: experience.name })}
            dirty={formState.name !== experience.name}
          />
          <ResettableInput
            label="Szervező neve"
            value={formState.conductorName}
            onChange={(v) => setFormState({ ...formState, conductorName: v })}
            onReset={() => setFormState({ ...formState, conductorName: experience.conductorName })}
            dirty={formState.conductorName !== experience.conductorName}
          />
          <ResettableInput
            label="Tematika"
            value={formState.type}
            onChange={(v) => setFormState({ ...formState, type: v })}
            onReset={() => setFormState({ ...formState, type: experience.type })}
            dirty={formState.type !== experience.type}
          />
        </span>
        <span className={styles.row}>
          <ResettableInput
            label="Ár (Ft / fő / alkalom)"
            value={handleIntFormValueDisplay(formState.price)}
            onChange={(v) => setFormState({ ...formState, price: handleNaNFormValue(v) })}
            onReset={() => setFormState({ ...formState, price: experience.price })}
            dirty={formState.price !== experience.price}
          />
          <ResettableInput
            label="Maximális létszám"
            value={handleIntFormValueDisplay(formState.defaultMaxPeople)}
            onChange={(v) =>
              setFormState({ ...formState, defaultMaxPeople: handleNaNFormValue(v) })
            }
            onReset={() =>
              setFormState({ ...formState, defaultMaxPeople: experience.defaultMaxPeople })
            }
            dirty={formState.defaultMaxPeople !== experience.defaultMaxPeople}
          />
          <ResettableInput
            label="Minimális létszám"
            value={handleIntFormValueDisplay(formState.defaultMinPeople || 0)}
            onChange={(v) =>
              setFormState({ ...formState, defaultMinPeople: handleNaNFormValue(v) })
            }
            onReset={() =>
              setFormState({ ...formState, defaultMinPeople: experience.defaultMinPeople })
            }
            dirty={formState.defaultMinPeople !== experience.defaultMinPeople}
          />
          <ResettableInput
            label="Időtartam (óra)"
            value={durationString}
            onChange={(v) => setDurationString(sanitizeFloat(v))}
            onReset={() => {
              setDurationString(experience.lengthInHours.toFixed(1))
              setFormState({ ...formState, lengthInHours: experience.lengthInHours })
            }}
            dirty={durationString !== experience.lengthInHours.toFixed(1)}
          />
        </span>
      </div>
      <div className={styles.block}>
        <h1 className={styles.blockTitle}>Elérhetőség</h1>
        <span className={styles.row}>
          <ResettableInput
            label="Irányítószám"
            value={handleIntFormValueDisplay(formState.zipCode)}
            onChange={(v) => setFormState({ ...formState, zipCode: handleNaNFormValue(v) })}
            onReset={() => setFormState({ ...formState, zipCode: experience.zipCode })}
            dirty={formState.zipCode !== experience.zipCode}
          />
          <ResettableInput
            label="Város / település"
            value={formState.city}
            onChange={(v) => setFormState({ ...formState, city: v })}
            onReset={() => setFormState({ ...formState, city: experience.city })}
            dirty={formState.city !== experience.city}
          />
        </span>
        <span className={styles.row}>
          <ResettableInput
            label="Cím (1. sor)"
            value={formState.addressLine1}
            onChange={(v) => setFormState({ ...formState, addressLine1: v })}
            onReset={() => setFormState({ ...formState, addressLine1: experience.addressLine1 })}
            dirty={formState.addressLine1 !== experience.addressLine1}
          />
          <ResettableInput
            small
            label="Cím (2. sor)"
            value={formState.addressLine2 ?? ""}
            onChange={(v) => setFormState({ ...formState, addressLine2: v === "" ? null : v })}
            onReset={() => setFormState({ ...formState, addressLine2: experience.addressLine2 })}
            dirty={formState.addressLine2 !== experience.addressLine2}
          />
        </span>
      </div>
      <div className={styles.block}>
        <h1 className={styles.blockTitle}>Részletek</h1>
        <span className={styles.row}>
          <ResettableInput
            label="Rövid leírás"
            value={formState.shortDescription}
            onChange={(v) => setFormState({ ...formState, shortDescription: v })}
            onReset={() =>
              setFormState({ ...formState, shortDescription: experience.shortDescription })
            }
            dirty={formState.shortDescription !== experience.shortDescription}
          />
        </span>
        <span className={styles.row}>
          <ResettableInput
            label="Teljes leírás"
            value={formState.description.join("\n\n")}
            onChange={(v) =>
              setFormState({ ...formState, description: v.replace("\r", "\n").split("\n\n") })
            }
            onReset={() => setFormState({ ...formState, description: experience.description })}
            dirty={formState.description !== experience.description}
            rows={10}
          />
        </span>
        <span className={styles.row}>
          <ResettableInput
            label="Szükséges felszerelés és eszközök (vesszővel elválasztott lista)"
            value={formState.equipment.join(", ")}
            onChange={(v) =>
              setFormState({ ...formState, equipment: v.split(",").map((s) => s.trim()) })
            }
            onReset={() => setFormState({ ...formState, equipment: experience.equipment })}
            dirty={formState.equipment !== experience.equipment}
          />
        </span>
      </div>
      <div className={styles.block}>
        <h1 className={styles.blockTitle}>Lezárt események</h1>
        {events.past.map((e) => (
          <EventDisplay updateEventList={updateEventList} key={e.id} event={e} />
        ))}
        {events.past.length === 0 ? (
          <div className={styles.placeholder}>Nincs megjeleníthető esemény</div>
        ) : null}
      </div>
      <div className={styles.block}>
        <h1 className={styles.blockTitle}>Ütemezett események</h1>
        {events.future.map((e) => (
          <EventDisplay updateEventList={updateEventList} key={e.id} event={e} editable />
        ))}
        {events.future.length === 0 ? (
          <div className={styles.placeholder}>Nincs megjeleníthető esemény</div>
        ) : null}
      </div>
      <div className={styles.block}>
        <h1 className={styles.blockTitle}>Új esemény felvétele</h1>
        <CreateEventView experience={experience} updateEventList={updateEventList} />
      </div>
    </>
  )
}
