import React, {useState, useEffect, useContext} from 'react';
import {
  Select,
  FormControl,
  MenuItem,
  InputLabel,
} from '@material-ui/core'
import MMButton from 'src/common-components/MMButton/MMButton';
import MMInput from 'src/common-components/MMInput/MMInput';
import styles from "../Admin.module.scss"
import FinancialTable from 'src/common-components/Financial/FinancialTable';
import { snakeCasefyObject } from '../../../utils/misc';
import { patchMaster } from 'src/apicalls/CraftsmanAdmin/patchMaster';
import { GlobalContext } from '../../../App';
import { getMaster } from 'src/apicalls/Craftsman/getMaster';

const Financial = () => {
  const [billingName, setBillingName] = useState("")
  const [billingAddress, setBillingAddress] = useState("")
  const [accountNumber, setAccountNumber] = useState("")
  const [tax_form, setTaxForm] = useState("")

  const {user} = useContext(GlobalContext)

  useEffect(() => {
    const getData = async () => {
      if (user && user.masterId) {
        const res = await getMaster(user.masterId)
        if (res.data.billingName) {
          setBillingName(res.data.billingName)
        }
        if (res.data.setBillingAddress) {
          setBillingAddress(res.data.setBillingAddress)
        }
        if (res.data.accountNumber) {
          setAccountNumber(res.data.accountNumber)
        }
        if (res.data.tax_form) {
          setTaxForm(res.data.tax_form)
        }
      }
    }
    getData()
  }, [user])

  const handleSaveFinancialPreferences = async () => {
    const masterId = user && user.masterId ? user.masterId : null
    const data = snakeCasefyObject({ billingName, billingAddress, accountNumber, tax_form, masterId })
    //data = {...data, ...{city: "", description: "", name: "", profession: ""}}
    const res = await patchMaster(data)
    if (res) {
      alert("Sikeres mentés")
    }
  }

  return (
    <>
      <FormControl fullWidth>
        <p className={styles.blockTitle}>Számlázási adatok</p>
        <MMInput className={styles.craftsmanInput} label="Név" value={billingName} setValue={setBillingName} />
        <MMInput className={styles.craftsmanInput} label="Cím" value={billingAddress} setValue={setBillingAddress} />
        <MMInput className={styles.craftsmanInput} label="Számlaszám" value={accountNumber} setValue={setAccountNumber} />
        <>
          <label>Adózási forma</label>
          <Select
            labelId="tax"
            id="tax"
            placeholder='Adózási forma'
            value={tax_form}
            label="Adózási forma"
            onChange={(e) => {setTaxForm(e.target.value as string)}}
          >
            <MenuItem value="kata">KATA</MenuItem>
            <MenuItem value="atalany">Átalányadó</MenuItem>
            <MenuItem value="kft">Kft.</MenuItem>
            <MenuItem value="bt">Bt.</MenuItem>
            <MenuItem value="egyeb">Egyéb</MenuItem>
          </Select>
        </>
        <MMButton handleClick={handleSaveFinancialPreferences} className={styles.saveButton} theme="callToAction">
          Mentés
        </MMButton>
      </FormControl>
      <p className={styles.blockTitle}>Beérkezett befizetések</p>
      <FinancialTable />
    </>
  )
}

export default Financial;
