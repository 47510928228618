import React, { useContext } from "react"
import { GlobalContext } from "../../App"
import { useMediaQuery } from "@material-ui/core"
import styles from "./AboutUs.module.scss"
import { useHistory } from "react-router"

const AboutUs: React.FC = ({}) => {
  const history = useHistory()
  const isMobile = useMediaQuery("(max-width: 600px)")
  const { user } = useContext(GlobalContext)

  return (
    <div className={`${styles.outer} ${isMobile ? styles.mobile : ""}`}>
      <div className={`${styles.container} ${isMobile ? styles.mobile : ""}`}>
        <div className={`${styles.inner} ${isMobile ? styles.mobile : ""}`}>
          <div className={styles.logoTexts} />
          <div className={styles.text}>
            <h1>Válogass kézműves programjaink közül és tanulj valami újat!</h1>
            <div onClick={() => history.push("/listings")} className={styles.button}>
              Tovább a programokra
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default AboutUs
