import React from "react"
import { Tooltip, TooltipProps } from "@material-ui/core"
import styles from "./MMTooltip.module.scss"

const MMTooltip: React.FC<Omit<TooltipProps, "title"> & { title: string }> = (props) => {
  const { title, children } = props
  return (
    <Tooltip {...props} title={<p className={styles.tooltipTitle}>{title}</p>}>
      {children}
    </Tooltip>
  )
}

export default MMTooltip
