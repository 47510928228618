import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers"
import React, { useContext } from "react"
import { GlobalContext } from "../../../App"
import MMInput from "../../../common-components/MMInput/MMInput"
import styles from "./BasicSearchFields.module.scss"
import DateFnsUtils from "@date-io/date-fns"

const BasicSearchFields: React.FC = () => {
  const { searchStart, setSearchStart, searchEnd, setSearchEnd, setNumOfGuests, numOfGuests } =
    useContext(GlobalContext)

  const setNumOfGuestsToState = (data: string) => {
    if (data === "" || data === "0") setNumOfGuests(null)
    else if (!isNaN(parseInt(data))) setNumOfGuests(Math.min(99, parseInt(data)))
  }

  return (
    <div className={styles.datePickerContainer}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <DatePicker
          className={styles.calendarInput}
          label="Mettől"
          value={searchStart}
          color="secondary"
          onChange={(date) => setSearchStart(date as any)}
          inputProps={{ value: searchStart?.toLocaleDateString() || "mindegy" }}
          disablePast
        />
        <div className={styles.calendarResetButton} onClick={() => setSearchStart(new Date())}>
          {searchStart !== null ? <i className={["material-icons"].join(" ")}>close</i> : null}
        </div>
        <DatePicker
          className={styles.calendarInput}
          label="Meddig"
          value={searchEnd}
          color="secondary"
          onChange={(date) => setSearchEnd(date as any)}
          inputProps={{ value: searchEnd?.toLocaleDateString() || "" }}
          disablePast
        />
        <div className={styles.calendarResetButton} onClick={() => setSearchEnd(null)}>
          {searchEnd !== null ? <i className={["material-icons"].join(" ")}>close</i> : null}
        </div>
        <MMInput
          className={styles.input}
          label="Vendégek"
          value={numOfGuests ? numOfGuests.toString() : ""}
          setValue={setNumOfGuestsToState}
          variant="standard"
          size="medium"
        />
      </MuiPickersUtilsProvider>
    </div>
  )
}
export default BasicSearchFields
