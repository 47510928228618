import { createOrder } from "../../../../apicalls/createOrder"
import { GuestCreationData, OrderCreationData } from "../../../../types"
import { useBillingFields } from "../../../../utils/hooks"

type BPType = ReturnType<typeof useBillingFields>

export const makeCreateGuestOrderPayload = ({
  billingProps,
  email,
  eventID,
  numOfGuests,
}: {
  billingProps: BPType
  email: string
  eventID: number
  numOfGuests: number
}) => ({
  addressLine1: billingProps.addressLine1,
  addressLine2: billingProps.addressLine2,
  city: billingProps.city,
  fullName: billingProps.fullName,
  zipCode: billingProps.zipCode,
  numOfGuests: numOfGuests || 0,
  email,
  eventID,
  companyName: billingProps.companyName,
  vatNumber: billingProps.vatNumber,
  contactName: billingProps.contactName,
})

export const makeCreateOrderPayload = ({
  numOfGuests,
  eventID,
  billingProps,
}: {
  billingProps: BPType
  eventID: number
  numOfGuests: number
}) => ({
  addressLine1: billingProps.addressLine1,
  addressLine2: billingProps.addressLine2,
  city: billingProps.city,
  zipCode: billingProps.zipCode,
  fullName: billingProps.fullName,
  numOfGuests: numOfGuests || 0,
  eventID,
  companyName: billingProps.companyName,
  vatNumber: billingProps.vatNumber,
  email: null,
  contactName: billingProps.contactName,
})

export const handeToPaymentClick = async (
  setErrors: (e: any) => void,
  validate: (v: OrderCreationData | (OrderCreationData & GuestCreationData)) => {
    [key: string]: string | boolean
  },
  values: OrderCreationData | (OrderCreationData & GuestCreationData)
) => {
  setErrors(null)
  const errors = validate(values)
  if (Object.keys(errors).length > 0) {
    setErrors(errors)
  } else {
    const res = await createOrder(values)
    if (res.data.data) window.location.href = res.data.data
  }
}
