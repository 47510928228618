import React, { useState, useEffect, useContext } from "react"
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers"
import DateFnsUtils from "@date-io/date-fns"
import { MMEvent, MMExperience } from "src/types"
import MMButton from "src/common-components/MMButton/MMButton"
import MMInput from "src/common-components/MMInput/MMInput"
import { downloadEventOrdersAdmin } from "src/apicalls/admin/downloadEventOrdersAdmin"
import MMTooltip from "src/common-components/MMTooltip/MMTooltip"
import { patchEventAdmin } from "src/apicalls/admin/patchEventAdmin"
import { GlobalContext } from "src/App"
import styles from "./EventDisplay.module.scss"
import { createEventAdmin } from "src/apicalls/admin/createEventAdmin"
import { createEventMasterAdmin } from '../../apicalls/CraftsmanAdmin/createEventMasterAdmin';

interface Props {
  experience: MMExperience
  updateEventList: () => void
}

const CreateEventView: React.FC<Props> = ({ experience, updateEventList }) => {
  const [eventDate, setEventDate] = useState<Date>(new Date())
  const [maxPeople, setMaxPeople] = useState(experience.defaultMaxPeople)
  const { user } = useContext(GlobalContext)

  async function onCreate() {
    let res;
    if (user?.isMaster) {
      res = await createEventMasterAdmin(experience.id, {
        maxPeople,
        startTime: eventDate.valueOf() / 1000,
      })
    } else {
      res = await createEventAdmin(experience.id, {
        maxPeople,
        startTime: eventDate.valueOf() / 1000,
      })
    }
    updateEventList()
  }

  return (
    <div className={styles.eventDisplay}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <DateTimePicker
          className={styles.calendarInput}
          label="Időpont"
          inputVariant="outlined"
          value={eventDate}
          color="secondary"
          ampm={false}
          disableToolbar
          onChange={(date) => date && setEventDate(date)}
          inputProps={{
            value: eventDate.toLocaleString("hu", { dateStyle: "medium", timeStyle: "short" }),
          }}
          disablePast
        />
      </MuiPickersUtilsProvider>

      <span className={[styles.label, styles.small].join(" ")}>
        <MMInput
          label="Maximális létszám"
          value={maxPeople.toString()}
          setValue={(v) => {
            const val = parseInt(v)
            if (isNaN(val)) {
              setMaxPeople(0)
            } else {
              setMaxPeople(val)
            }
          }}
        />
      </span>

      <span className={styles.spacer} />
      <MMButton handleClick={onCreate} className={styles.button} icon="add">
        Létrehozás
      </MMButton>
    </div>
  )
}

export default CreateEventView
