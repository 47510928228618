import React, { useEffect, useRef, useState } from "react"
import { useHistory } from "react-router"
import { STATIC_URL } from "src/constants"
import { MMExperience, MMExperienceBasicData } from "../../types"
import styles from "./ExperienceCard.module.scss"

interface Props {
  experience: MMExperienceBasicData
  isDisabled?: boolean
}

const ExperienceCard: React.FC<Props> = ({ experience, isDisabled = false }) => {
  const history = useHistory()

  const [height, setHeight] = useState(0)
  const [, setRenderCounter] = useState(0)
  const [imageLoaded, setImageLoaded] = useState(false)

  //need to rerender on window resize but rerendering every time the event fires is very resource heavy
  useEffect(() => {
    let resizeTimeout: NodeJS.Timeout
    const onResize = () => {
      resizeTimeout && clearTimeout(resizeTimeout)
      resizeTimeout = setTimeout(() => setRenderCounter((s) => s + 1), 100)
    }
    window.addEventListener("resize", onResize)
    return () => window.removeEventListener("resize", onResize)
  }, [])

  const openExperience = () => {
    history.location.state = { experienceID: experience.id }
    history.push({ pathname: "/experience/" + experience.id })
  }

  const containerClasses = [styles.container]
  if (isDisabled) {
    containerClasses.push(styles.disabled)
  }

  return (
    <div onClick={openExperience} className={containerClasses.join(" ")}>
      <div className={styles.name}>{experience.name}</div>
      <div className={styles.card}>
        <div
          className={styles.imgContainer}
          style={{ height }}
          ref={(ref) => setHeight((ref?.offsetWidth || 1) * 1.1)}
        >
          <img
            onLoad={() => setTimeout(() => setImageLoaded(true), 50 + Math.random() * 100)}
            src={STATIC_URL + experience.mainImageName}
            className={styles.img}
          />
          <div className={`${styles.loader} ${imageLoaded ? styles.hidden : ""}`}>
            <img src={"/logo/logo-bird.svg"} className={styles.icon} />
          </div>
        </div>
        <div className={styles.info}>
          <div className={styles.topRow}>
            {experience.likeNumber ? (
              <div className={styles.ratingBox}>
                <i className="material-icons" style={{color: "#5D6146"}}>favorite</i>
                <p className={styles.ratingNumber}>{`${experience.likeNumber}`}</p>
              </div>
            ) : (
              <>
                {/* <p>Még nincs értékelve</p> */}
              </>
            )}
            {height >= 300 ? <p className={styles.city}>{`${experience.city}`}</p> : null}
          </div>
          {height < 300 ? <p className={styles.city}>{`${experience.city}`}</p> : null}
          <p className={styles.shortDesc}>{experience.shortDescription}</p>
          <p className={[styles.price].join(" ")}>
            <span className={[experience.originalPrice !== null ? styles.sale : null].join(" ")}>
              {experience.price} Ft
            </span>
            {experience.originalPrice !== null ? (
              <span className={styles.originalPrice}>{experience.originalPrice} Ft</span>
            ) : null}
          </p>
        </div>
      </div>
    </div>
  )
}
export default ExperienceCard
