import { useMediaQuery } from '@material-ui/core'
import React, { useContext, useState } from "react"
import { createRateImage } from 'src/apicalls/createRateImage'
import { GlobalContext } from 'src/App'
import { patchRating } from "../../../apicalls/patchRating"
import MMButton from "../../../common-components/MMButton/MMButton"
import MMInput from "../../../common-components/MMInput/MMInput"
import MMModal from "../../../common-components/MMModal/MMModal"
import { ExperienceImage, ExperienceImageMetadata, OwnRating } from "../../../types"
import styles from "./RateModal.module.scss"
import RatingDisplay from "./RatingDisplay"

interface Props {
	myRating: OwnRating | null
	open: boolean
	closeModal: () => void
	orderID: number
	onSuccess?: () => void
}

const RateModal: React.FC<Props> = ({ open, closeModal, myRating, orderID, onSuccess }) => {
  const { user } = useContext(GlobalContext)
	const [score, setScore] = useState(myRating?.score || -1)
	const [comment, setComment] = useState(myRating?.comment || "")
  const allowedFileExtensions = ".jpg,.jpeg,.png"
  const isLarge = useMediaQuery("(min-width: 1900px)")

	const updateOwnRating = async () => {
		try {
			const res = await patchRating({ comment, score, orderID })
		} catch (err) {
			console.log(err)
		}
		onSuccess?.()
		closeModal()
	}

  function onUploadClick() {
    document.getElementById("mm-admin-image-upload" + user?.email)?.click()
	};

	async function onUploadFileSelected(file: File) {
    if (!file) return

    // TODO: No need for this as server side compression is now live
    // if (file.size > 2 * 1024 * 1024) {
    //   alert("Túl nagy fájl (>1MB)")
    //   return
    // }

    const metadata: ExperienceImageMetadata = {
      filename: file.name,
      main: true,
      ordinalNumber: 1,
      description: null,
		}
		const r = await createRateImage(metadata, file)
    //if (r.result === "ok") loadImageList()
  }

	return (
		<MMModal handleClose={closeModal} open={open} title="Értékelés">
			<div className={styles.container}>
				<div className={styles.starsContainer}>
					{/* <RatingDisplay size="big" score={score} setScore={setScore} /> */}
					<h3 className={styles.rateTitle}>Ajánljaná a workshopot másnak?</h3>
					<div className={styles.btnRow}>
						<MMButton icon='favorite' className={score === 1 ? [styles.active, styles.likeButton].join(" ") : styles.likeButton} handleClick={() => setScore(1)}>
							Igen
						</MMButton>
						<MMButton icon='thumb_down' className={score === 0 ? [styles.active, styles.likeButton].join(" ") : styles.likeButton} handleClick={() => setScore(0)}>
							Nem
						</MMButton>
					</div>
				</div>
				<div
          onClick={onUploadClick}
          className={[styles.imageWrapper, isLarge ? styles.large : null].join(" ")}
        >
          <input
            type="file"
            accept={allowedFileExtensions}
            id={"mm-admin-image-upload" + user?.email}
						style={{ display: "none" }}
						onChange={(e) => {
              if (e.target.files) onUploadFileSelected(e.target.files[0])
            }}
          />
          <div className={styles.uploadButton}>
						Fotó hozzáadása az elkészült alkotásról vagy a workshopról.
          </div>
        </div>
				<MMInput rows={6} value={comment} setValue={setComment} label="Szöveges értékelés" />
				<MMButton handleClick={updateOwnRating} theme="callToAction" className={styles.button}>
					Mentés
				</MMButton>
			</div>
		</MMModal>
	)
}
export default RateModal
