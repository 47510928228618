import CryptoJS from "crypto-js"
import { Address } from "../types"

export const hash = (string: string) => {
  return CryptoJS.SHA256(string).toString()
}

export const addressToString = (a: Address) => {
  return `${a.zipCode} ${a.city}, ${a.addressLine1} ${a.addressLine2 || ""}`
}

export const utcToDateString = (x: number) => {
  return new Date(x * 1000).toLocaleDateString()
}

export const utcToDateTimeString = (x: number) => {
  return new Date(x * 1000).toLocaleString()
}

export const getOneWeekFromNow = () => {
  const date = new Date()
  date.setDate(date.getDate() + 7)
  return date
}

export const snakeCasefy = (str: string) => {
  return str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`)
}

export const snakeCasefyObject = (o: { [key: string]: any }) => {
  const resultObject: { [key: string]: any } = {}
  for (let key in o) {
    const snakeKey = snakeCasefy(key)
    resultObject[snakeKey] = o[key]
  }
  return resultObject
}
