import React, { useContext, useState } from "react"
import { useHistory } from "react-router"
import { GlobalContext } from "../../App"
import BasicSearchFields from "../../pages/Experience/Order/BasicSearchFields"
import MMButton from "../MMButton/MMButton"
import MMInput from "../MMInput/MMInput"
import MMModal from "../MMModal/MMModal"
import styles from "./SearchPopup.module.scss"

interface Props {
	open: boolean
	onClose: () => void
}

const SearchPopup: React.FC<Props> = ({ onClose, open }) => {
	const { setFilterRadius, setFilterLocation, filterRadius, filterLocation } =
		useContext(GlobalContext)

	const [type, setType] = useState("")

	const history = useHistory()

	const setDistanceToState = (data: string) => {
		if (data === "") setFilterRadius(0)
		else if (!isNaN(parseInt(data))) setFilterRadius(Math.min(1000, parseInt(data)))
	}

	const onSearchClick = () => {
		history.push({ pathname: "/listings" })
		onClose()
	}

	return (
		<MMModal open={open} title="Események szűrése" handleClose={onClose}>
			<BasicSearchFields />
			<div className={styles.inputRow}>
				<MMInput
					label="Távolság"
					value={filterRadius ? filterRadius.toString() : ""}
					setValue={setDistanceToState}
					className={styles.input}
				/>
				<MMInput
					label="Innen"
					value={filterLocation || ""}
					setValue={setFilterLocation}
					className={styles.input}
				/>
			</div>

			<MMInput label="Program típus" value={type} setValue={setType} className={styles.input} />
			<MMButton className={styles.button} handleClick={onSearchClick}>
				Mehet
			</MMButton>
		</MMModal>
	)
}
export default SearchPopup
