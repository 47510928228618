const built = process.env.NODE_ENV === "production"
const production = false

export const APIBASEURL = production
  ? "https://api.marminalunk.com/v1/"
  : built
  ? "https://api.marminalunk.com/dev/"
  : "https://api.marminalunk.com/dev/"

export const APPBASEURL = production
  ? "https://marminalunk.com/"
  : built
  ? "https://dev.marminalunk.com/"
  : "http://localhost:3000/"

// Necessary to avoid cross-origin iframe block
document.domain = production ? "marminalunk.com" : built ? "dev.marminalunk.com" : "localhost"
const fbClientID = built ? "156153936527368" : "5508741649195759"

export const FBloginURI = `https://www.facebook.com/v10.0/dialog/oauth?client_id=${fbClientID}&redirect_uri=${APPBASEURL}fb&scope=email`

export const GoogleClientID = production
  ? "603097532328-fskg7oiv4tfnhbdba722npafjrprfe8i.apps.googleusercontent.com"
  : "603097532328-7gut0kbk597kef0sk5eo30lsju2mi7vn.apps.googleusercontent.com"

export const STATIC_URL = production
  ? "https://static.marminalunk.com/v1/media/"
  : built
  ? "https://static.marminalunk.com/dev/media/"
  : "/media/"
