import styles from "./MMSelect.module.scss"
import Select from "@material-ui/core/Select"
import MenuItem from "@material-ui/core/MenuItem"
import { SelectOptions } from "../../types"

interface Props {
	options: SelectOptions
	label: string
	infoText?: string
	value: string
	setValue: (val: any) => void
	tabIndex?: number
}

const MMSelect: React.FC<Props> = ({ options, label, infoText, value, setValue, tabIndex }) => {
	const optionElements = options.map(({ label, value }, index) => {
		return (
			<MenuItem key={index} value={value}>
				{label || value}
			</MenuItem>
		)
	})

	return (
		<div className={styles.container}>
			<Select
				value={value}
				onChange={(e) => setValue(e.target.value as string)}
				className={styles.selectContainer}
				inputProps={{ tabIndex }}
				variant="outlined"
			>
				{optionElements}
			</Select>
		</div>
	)
}
export default MMSelect
