import axios from "axios"
import { APIBASEURL } from "../../constants"
import { ApiResponse, BillingInformation, OwnRating, RegisterApiResponse, User } from "../../types"

export const patchMaster = async (
	data: any
) => {
	const res = await axios({
		method: "patch",
		url: APIBASEURL + "master",
		data,
		withCredentials: true,
	})

	return res.data as ApiResponse<any, User>
}
