import axios from "axios"
import { APIBASEURL } from "src/constants"
import { ApiResponse, MMExperience } from "src/types"

export const patchExperienceAdmin = async (data: MMExperience) => {
  const res = await axios({
    method: "PATCH",
    url: APIBASEURL + "admin/experience",
    data: { data: data },
    withCredentials: true,
  })

  return res.data as ApiResponse<string>
}
