import axios, { AxiosResponse } from "axios"
import { APIBASEURL } from "../constants"
import { LoginApiResponse } from "../types"

export const loginFacebook = async (code: string) => {
	const res = await axios({
		method: "POST",
		url: APIBASEURL + "fb-login",
		data: { token: code },
		withCredentials: true,
	})
	return res as AxiosResponse<LoginApiResponse>
}
