import React, { useContext, useState } from "react"
import MMButton from "../../../common-components/MMButton/MMButton"
import styles from "./Login.module.scss"
import { ReactComponent as GoogleLogo } from "../../../assets/google-logo.svg"
import { ReactComponent as MMLogo } from "../../../assets/mm-logo-bird.svg"
import { SlideLayoutChildProps } from "../../../types"
import MMInput from "../../../common-components/MMInput/MMInput"
import { login } from "../../../apicalls/login"
import { validateEmail } from "../../../utils/validations"
import { GlobalContext } from "../../../App"
import { useGoogleLogin } from "react-google-login"
import { loginGoogle } from "../../../apicalls/loginGoogle"
import Loader from "../../../common-components/Loader/Loader"
import { loginFacebook } from "../../../apicalls/loginFacebook"
import { FBloginURI, GoogleClientID } from "../../../constants"
import { Checkbox, FormControlLabel, FormGroup } from '@material-ui/core'
import MMModal from 'src/common-components/MMModal/MMModal'
import { forgotPassword } from '../../../apicalls/forgotPassword';

const facebookLogin = async (tokenCallback: (token: string) => void) => {
  window.open(
    FBloginURI,
    "Facebook login",
    "left=100, top=100, resizable=no, scrollbar=no, menubar=no, location=no, directories=no, status=no, height=450, width=650"
  )
  ;(window as any).fbMMLoginCallback = tokenCallback
}

interface LoginErrors {
  "invalid credentials"?: boolean
  "invalid email"?: boolean
}

interface Props extends SlideLayoutChildProps {
  closeModal: () => void
  setIsMaster: (isMaster: number) => void
}

const RegistrationTypes = [
  "vendég",
  "kézműves",
  "felhasználó"
]

const Login: React.FC<Props> = ({ setTransformed, closeModal, setIsMaster }) => {
  const { setUser } = useContext(GlobalContext)

  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [errors, setErrors] = useState<LoginErrors | null>(null)
  const [loading, setLoading] = useState(false)
  const [isRegistrationTypeSelected, setIsRegistrationTypeSelected] = useState(0);
  const [isASZFChecked, setIsASZFChecked] = useState(false);
  const [aszfModal, setAszfModal] = useState(false);
  const [isForgotPassword, setIsForgotPassword] = useState(false);
  const [isSuccessForgotPassword, setIsSuccessForgotPassword] = useState(false);

  const { signIn } = useGoogleLogin({
    clientId: GoogleClientID,
    onSuccess: async (googleResponse: any) => {
      setLoading(true)
      const res = await loginGoogle(googleResponse.tokenId)
      if (res.result === "ok") {
        setUser(res.data)
        closeModal()
        return
      }
      //TODO: handle error
    },
    onFailure: (error: any) => {
      console.log("Google error:", JSON.stringify(error))
    },
  })

  const fbTokenCallback = async (token: string) => {
    setLoading(true)
    const res = await loginFacebook(token)
    if (res.status !== 200) {
      //TODO: show error noti
      return
    }
    if (res.data.result === "ok") {
      setUser(res.data.data)
      closeModal()
      return
    }
  }

  const handleLogin = async () => {
    setErrors(null)

    if (!validateEmail(email)) {
      setErrors({ "invalid email": true })
      return
    }

    const res = await login({ email, password, persistent: false })

    if (res.result === "error") {
      if (res.data === "invalid credentials") {
        setErrors({ "invalid credentials": true })
        return
      } else {
        alert("Sajnos nem sikerült bejelentkeznie a helytelen email cím vagy helytelen jelszó miatt. Kérjük próbálja meg újra!")
      } //TODO: context error popup
      return
    }

    console.log(res.data)
    setUser(res.data)
    closeModal()
  }

  const checkASZFIsChecked = () => {
    if (isASZFChecked) {
      return true;
    }
    setAszfModal(true)
    return false
  }

  const handleForgotPassword = async () => {
    const res = await forgotPassword(email)
    console.log(res)
    if (res.status === 200) {
      setIsSuccessForgotPassword(true)
    } else {
      alert("Hiba történt! Probáld újra!")
    }
  }

  if (loading) return <Loader width="5rem" />

  return (
    <div className={styles.container}>
      {
        isForgotPassword ?
          <>
            {
              isSuccessForgotPassword ?
                <h3 className={styles.forgotPasswordTitle}>
                  A megadott e-mail címedre elküldtük az új jelszavadat. Belépés után a profilodban tudod módosítani.
                </h3>
                : (
                  <>
                    <h3 className={styles.forgotPasswordTitle}>Kérlek add meg a regisztrált e-mail címedet</h3>
                    <MMInput
                      value={email}
                      setValue={setEmail}
                      label="E-mail"
                      icon="alternate_email"
                      className={styles.input}
                      name="email"
                      errorText={errors && errors["invalid email"] ? "Helytelen e-mail cím" : undefined}
                    />
                    <MMButton
                      className={styles.newPasswordButton}
                      theme="callToAction"
                      handleClick={handleForgotPassword}
                    >
                      Új jelszó igénylése
                    </MMButton>
                  </>
                )
            }
            <MMButton handleClick={() => setIsForgotPassword(false)}>
              Vissza
            </MMButton>
          </>
          : (
            <>
              <MMModal
                handleClose={() => { setAszfModal(false) }}
                children={<p>Kérlek először fogadd el a felhasználási feltételeket!</p>}
                title="Figyelem"
                open={aszfModal}
              />
              <MMInput
                value={email}
                setValue={setEmail}
                label="E-mail"
                icon="alternate_email"
                className={styles.input}
                name="email"
                errorText={errors && errors["invalid email"] ? "Helytelen e-mail cím" : undefined}
              />
              <MMInput
                value={password}
                setValue={setPassword}
                label="Jelszó"
                icon="lock"
                className={styles.input}
                name="password"
                type="password"
                onEnter={handleLogin}
              />
              <p
                className={styles.forgotPasswordLink}
                onClick={() => setIsForgotPassword(true)}>
                Elfelejtettem a jelszavamat
              </p>
              <MMButton theme="callToAction" handleClick={handleLogin}>
                Bejelentkezés
              </MMButton>
              <hr className={styles.texthr} content-text={
                isRegistrationTypeSelected === 0 ?
                  "Még nincs fiókja? Regisztráljon!"
                  : `Regisztrálás ${RegistrationTypes[isRegistrationTypeSelected]}ként`
              } />
              {
                isRegistrationTypeSelected === 0  ?
                  (
                    <div
                      className={styles.registrationTypeContainer}
                    >
                      <div className={styles.registrationTypeBox}
                        onClick={() => {setIsRegistrationTypeSelected(1); setIsMaster(1)}}
                      >
                        <span>Kézművesként</span>
                        <img src={require("../../../assets/ceramist-min.jpg").default} />
                      </div>
                      <div className={styles.registrationTypeBox}
                        onClick={() => { setIsRegistrationTypeSelected(2); setIsMaster(0) }}
                      >
                        <span>Felhasználóként</span>
                        <img src={require("../../../assets/users-min.jpg").default} />
                      </div>
                    </div>
                  ) :
                  (
                    <>
                      <FormGroup>
                        <FormControlLabel
                          control={<Checkbox
                            color="primary"
                            checked={isASZFChecked}
                            onChange={(e) => {setIsASZFChecked(e.target.checked)}}
                          />}
                          label={<div>
                            Elfogadom az <a href="/aszf" target="_blank">Általános Szerződési Feltételeket</a> és az <a href="/adatkezelesi" target="_blank">Adatkezelési nyilatkozatot</a>
                          </div>}
                        />
                      </FormGroup>
                      <MMButton
                        handleClick={() => {if(checkASZFIsChecked()) return setTransformed?.(true)}}
                        theme="default"
                        className={styles.bottomMargin}
                      >
                        <div className={styles.buttonContent}>
                          <MMLogo className={[styles.logo, styles.mmLogo].join(" ")} />
                          Új fiók létrehozása
                        </div>
                      </MMButton>
                      {isRegistrationTypeSelected === 2 &&
                        <>
                        <MMButton
                          theme="default"
                          className={styles.bottomMargin}
                          icon="facebook"
                          iconClassName={styles.facebookLogo}
                          logoAbsolute
                          handleClick={() => {if(checkASZFIsChecked()) return facebookLogin(fbTokenCallback)}}
                        >
                          Folytatás Facebook fiókkal
                        </MMButton>
                        <MMButton theme="default" handleClick={() => { if(checkASZFIsChecked()) return signIn}}>
                          <div className={styles.buttonContent}>
                            <GoogleLogo className={[styles.logo, styles.googleLogo].join(" ")} />
                            Folytatás Google fiókkal
                          </div>
                        </MMButton>
                      </>}
                    </>
                  )
              }
            </>
          )
      }
    </div>
  )
}
export default Login
