import React, {useState, useEffect, useContext} from 'react';
import { FormControl, TextareaAutosize, TextField } from '@material-ui/core';
import MMInput from 'src/common-components/MMInput/MMInput';
import MMButton from 'src/common-components/MMButton/MMButton';
import styles from "../Admin.module.scss"
import { getExperiencesAdmin } from 'src/apicalls/CraftsmanAdmin/getExperiencesAdmin';
import { MMExperience } from 'src/types';
import ExperienceDisplay from '../ExperienceDisplay';
import ExperienceImageEditor from '../ExperienceImageEditor';
import { patchMaster } from 'src/apicalls/CraftsmanAdmin/patchMaster';
import { snakeCasefyObject } from 'src/utils/misc';
import { GlobalContext } from 'src/App';
import { getMaster } from 'src/apicalls/Craftsman/getMaster';

const Profile = () => {
  const [name, setName] = useState("")
  const [city, setCity] = useState("")
  const [profession, setProfession] = useState("")
  const [description, setDescription] = useState("")
  const [experienceList, setExperienceList] = useState<MMExperience[] | null>(null)
  const [master, setMaster] = useState({})

  const { user } = useContext(GlobalContext)

  useEffect(() => {
    updateExperienceList()
    const getData = async () => {
      if (user && user.masterId) {
        const res = await getMaster(user.masterId)
        setMaster(res.data)
        if (res.data.fullName) {
          setName(res.data.fullName)
        }
        if (res.data.city) {
          setCity(res.data.city)
        }
        if (res.data.profession) {
          setProfession(res.data.profession)
        }
        if (res.data.description) {
          setDescription(res.data.description)
        }
      }
    }
    getData()
  }, [user])

  async function updateExperienceList() {
    setExperienceList(null)
    const r = await getExperiencesAdmin()
    if (r.result === "ok") setExperienceList(r.data)
  }

  const handleSaveProfile = async () => {
    const masterId = user && user.masterId ? user.masterId : null
    const data = snakeCasefyObject({ name, city, profession, description, masterId })
    //data = {...data, ...{ billing_name: "", billing_address: "", account_number: "", tax_form: ""}}
    const res = await patchMaster(data)
    if (res) {
      alert("Sikeres mentés")
    }
  }

  return (
    <FormControl fullWidth>
      <ExperienceImageEditor
        loadImages={true}
        type="master"
        master={{id: user?.masterId}}
      />
      <p className={styles.blockTitle}>Adatok</p>
      <MMInput className={styles.craftsmanInput} label="Kézműves neve" value={name} setValue={setName} />
      <MMInput className={styles.craftsmanInput} label="Kézműves foglalkozásának megnevezése" value={profession} setValue={setProfession} />
      <MMInput className={styles.craftsmanInput} label="A város, ahol kézműves workshopokat fog tartani. Ha több városban is, tüntesse fel az összes városnevet." value={city} setValue={setCity} />
      <TextareaAutosize
        className={styles.craftsmanInput}
        placeholder="Meséljen személyes történetéről! Hogyan vált kézművessé? Miért pont ezt a kézműves szakmát választotta? Mit szeret szakmájában?"
        style={{ height: 100, padding: 10 }}
        value={description}
        onChange={(e) => setDescription(e.target.value)}
      />
      <MMButton handleClick={handleSaveProfile} className={styles.saveButton} theme="callToAction">
        Mentés
      </MMButton>
      {/* {experienceList === null ? (
        <p className={styles.blockTitle}>Még nem töltöttél fel programot.</p>
      ) : (
        <div className={styles.experienceList}>
          {experienceList.map((e) => (
            <ExperienceDisplay
              key={e.id}
              experience={e}
              refreshExperiences={updateExperienceList}
            />
          ))}
        </div>
      )} */}

    </FormControl>
  )
}

export default Profile;
