import { Fade, Modal } from "@material-ui/core"
import React, { useEffect, useRef, useState } from "react"
import styles from "./MMModal.module.scss"

interface Props {
	open: boolean
	title: string
	handleClose: () => void
	fixHeight?: boolean
}

const MMModal: React.FC<Props> = ({ open, children, title, handleClose, fixHeight = false }) => {
	const [height, setHeight] = useState<number | null>(null)

	return (
		<Modal
			aria-labelledby="transition-modal-title"
			aria-describedby="transition-modal-description"
			className={styles.container}
			open={open}
			onClose={handleClose}
			closeAfterTransition
		>
			<Fade in={open}>
				<div
					style={fixHeight ? { height: height || undefined } : undefined}
					className={styles.paper}
					ref={(ref) => setHeight(ref?.offsetHeight || null)}
				>
					<p className={styles.title}>
						{title}
						<i onClick={handleClose} className="material-icons">
							close
						</i>
					</p>
					<div className={styles.childrenContainer}>{children}</div>
				</div>
			</Fade>
		</Modal>
	)
}
export default MMModal
