import React, { useContext, useEffect, useState } from "react"
import { useHistory } from "react-router"
import { getFilteredExperiences } from "../../apicalls/getFilteredExperiences"
import { GlobalContext } from "../../App"
import ExperienceCard from "../../common-components/ExperienceCard/ExperienceCard"
import Loader from "../../common-components/Loader/Loader"
import PageBase from "../../common-components/PageBase/PageBase"
import AboutUs from "./AboutUs"
import ResponsiveCardContainer from "../../common-components/ResponsiveCardContainer/ResponsiveCardContainer"
import { MMEvent, MMExperience, MMExperienceBasicData } from "../../types"
import styles from "./Home.module.scss"
import { getFilteredPastExperiences } from 'src/apicalls/getFilteredPastExperiences'

const Home: React.FC = () => {
  const { user } = useContext(GlobalContext)
  const history = useHistory()

  const [newestExperiences, setNewestExperiences] = useState<MMExperienceBasicData[] | null>(null)
  const [pastExperiences, setPastExperiences] = useState<MMExperienceBasicData[] | null>(null)
  const [isLoader, setIsLoader] = useState(false);

  useEffect(() => {
    setIsLoader(true)
    const getNewestExperiences = async () => {
      const res = await getFilteredExperiences({})
      setNewestExperiences(res.data)
    }
    const getPastExperiences = async () => {
      const res = await getFilteredPastExperiences({})
      setPastExperiences(res.data);
    }
    getNewestExperiences()
    getPastExperiences();
    setIsLoader(false)
  }, [])

  const isNewestExperiencesExist = () => {
    return newestExperiences && newestExperiences.length > 0
  }

  // if (!newestExperiences) return <Loader width='6rem' />

  return (
    <div className={styles.wrapper}>
      <AboutUs />

      {/* Saved experiences */}
      {user?.savedExperiences && user.savedExperiences.length > 0 ? (
        <div className={styles.block}>
          <p className={styles.blockTitle}>Mentett programjaid</p>
          <ResponsiveCardContainer>
            {user?.savedExperiences.map((experience, index) => (
              <ExperienceCard key={index} experience={experience}/>
            ))}
          </ResponsiveCardContainer>
        </div>
      ) : null}

      {/* Future events */}
      {user?.futureEvents && user.futureEvents.length > 0 ? (
        <div className={styles.block}>
          <p className={styles.blockTitle}>Programok, amelyekre már jelentkeztél</p>
          <ResponsiveCardContainer>
            {user?.futureEvents.map(({ experience }, index) => (
              <ExperienceCard key={index} experience={experience} />
            ))}
          </ResponsiveCardContainer>
        </div>
      ) : null}

      <div id="mm-home-content" className={styles.block}>
      {isNewestExperiencesExist() ? (
        <>
          <p className={styles.blockTitle}>Legújabb programok</p>
          <ResponsiveCardContainer>
            {newestExperiences?.map((experience, index) => (
              <ExperienceCard key={index} experience={experience} />
            ))}
          </ResponsiveCardContainer>
          <p onClick={() => history.push({ pathname: "/listings" })} className={styles.more}>
            További programok
          </p>
        </>
      )
          :
            isLoader?
            <div className={styles.loader}>
              <Loader />
            </div>
            :
            <p className={styles.comingSoonTitle}>Új workshopok hamarosan</p>
      }
      </div>

      {/* Past events */}
      {pastExperiences && pastExperiences.length > 0 ? (
        <div className={styles.block}>
          <p className={styles.blockTitle}>Már megrendezett programok</p>
          <ResponsiveCardContainer>
            {pastExperiences.map((experience, index) => (
              <ExperienceCard key={index} experience={experience} isDisabled={true} />
            ))}
          </ResponsiveCardContainer>
          <p onClick={() => history.push({ pathname: "/expiredExperiences" })} className={styles.more}>
            További lejárt programok
          </p>
        </div>
      ) : null}
    </div>
  )
}
export default Home
