import axios from "axios"
import { APIBASEURL } from "src/constants"
import { ApiResponse, MMEvent } from "src/types"

export const createEventAdmin = async (
  experienceID: number,
  data: { maxPeople: number; startTime: number }
) => {
  const res = await axios({
    method: "POST",
    url: APIBASEURL + `admin/experience/${experienceID}/event`,
    data: data,
    withCredentials: true,
  })

  return res.data as ApiResponse<string>
}
