import { CircularProgress } from "@material-ui/core"
import React, { useEffect, useRef, useState } from "react"
import styles from "./Loader.module.scss"

interface Props {
	label?: string
	width?: string
	color?: "white" | "primary"
}

const Loader: React.FC<Props> = ({ label, width = "4rem", color = "primary" }) => {
	const ref = useRef<HTMLDivElement>(null)
	const [containerRendered, setContainerRendered] = useState(false)

	useEffect(() => {
		setContainerRendered(true)
	}, [])

	let size =
		width ||
		(ref.current
			? ref.current.offsetHeight > ref.current.offsetWidth
				? ref.current.offsetWidth
				: ref.current.offsetHeight
			: "4rem")

	return (
		<div ref={ref} className={[styles.container, styles[color]].join(" ")}>
			{containerRendered ? (
				<>
					<CircularProgress size={size} />
					{label ? <p>{label}</p> : null}
				</>
			) : null}
		</div>
	)
}
export default Loader
