import axios from "axios"
import { APIBASEURL } from "../constants"
import { LogoutApiResponse } from "../types"

export const logout = async () => {
	const res = await axios({
		method: "post",
		url: APIBASEURL + "logout",
		withCredentials: true,
	})

	return res.data as LogoutApiResponse
}
