import React, { useState, useContext } from "react"
import { GlobalContext } from "../../../App"
import MMButton from "../../../common-components/MMButton/MMButton"
import MMInput from "../../../common-components/MMInput/MMInput"
import { SlideLayoutChildProps } from "../../../types"
import styles from "./Register.module.scss"
import { checkPasswordComplexity } from "../../../utils/validations"
import { register } from "../../../apicalls/register"
import { validateEmail } from "../../../utils/validations"

interface RegisterErrors {
  "wrong email"?: "A megadott email cím hibás"
  "missing name"?: "Név megadása kötelező"
  "passwords mismatch"?: "A megadott jelszavak nem egyeznek"
  "email taken"?: "A megadott email-el már regisztráltak"
  "weak password"?: "A jelszó nem felel meg a kritériumoknak"
}

interface Props extends SlideLayoutChildProps {
  closeModal: () => void
  isMaster?: number
}

const Register: React.FC<Props> = ({ setTransformed, closeModal, isMaster = 0}) => {
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [passwordAgain, setPasswordAgain] = useState("")
  const [fullName, setFullName] = useState("")
  const [error, setError] = useState<RegisterErrors>()
  const [regSuccessful, setRegSuccessful] = useState(false)
  const { setUser } = useContext(GlobalContext)

  const handleRegister = async () => {
    setError({})
    if (!validateEmail(email)) {
      setError({ "wrong email": "A megadott email cím hibás" })
      return
    }

    if (!fullName) {
      setError({ "missing name": "Név megadása kötelező" })
      return
    }

    if (password !== passwordAgain) {
      setError({ "passwords mismatch": "A megadott jelszavak nem egyeznek" })
      return
    }
    if (!checkPasswordComplexity(password)) {
      setError({ "weak password": "A jelszó nem felel meg a kritériumoknak" })
      return
    }
    try {
      const res = await register({ email, password, fullName, isMaster })
      if (res.result !== "ok") throw new Error(res.data)

      setRegSuccessful(true)
      setUser(res.data)
      closeModal()
    } catch (e) {
      console.log(e);
      setError({ "email taken": "A megadott email-el már regisztráltak" })
    }
  }

  return (
    <div className={styles.container}>
      <MMInput
        value={email}
        setValue={setEmail}
        label="E-mail"
        icon="alternate_email"
        className={[styles.bottomMargin].join(" ")}
        errorText={error?.["email taken"]}
      />
      <MMInput
        value={fullName}
        setValue={setFullName}
        label="Teljes név"
        icon="person"
        className={styles.bottomMargin}
      />
      <MMInput
        value={password}
        setValue={setPassword}
        label="Jelszó"
        icon="lock"
        type="password"
        className={styles.bottomMargin}
        helperText="Legalább 8 karakter, legalább egy kisbetű, egy nagybetű és egy szám"
        errorText={error?.["weak password"]}
      />
      <MMInput
        value={passwordAgain}
        setValue={setPasswordAgain}
        label="Jelszó újra"
        type="password"
        icon="lock"
        className={styles.bottomMargin}
        errorText={error?.["passwords mismatch"]}
      />

      <MMButton
        theme="callToAction"
        handleClick={handleRegister}
        className={[styles.bottomMargin, styles.topMargin].join(" ")}
      >
        Regisztráció
      </MMButton>
      {isMaster === 0 &&
        (<MMButton handleClick={() => setTransformed?.(false)} theme="default">
          Vissza
        </MMButton>)
      }
    </div>
  )
}
export default Register
