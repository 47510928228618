import React, { useContext } from "react"
import Scrollbars from "react-custom-scrollbars-2"
import Header from "../Header/Header"
import { Button, Modal, Fade, Backdrop } from "@material-ui/core"
import { GlobalContext } from "src/App"
import styles from "./PageBase.module.scss"
import { Helmet } from "react-helmet"
import Footer from '../Footer/Footer'

const PageBase: React.FC = ({ children }) => {
  const { confirmModal, showConfirmModal } = useContext(GlobalContext)

  const onConfirmModalClose = () => {
    showConfirmModal(false)
    confirmModal?.onCancel?.()
  }

  return (
    <>
      <Helmet>
        <title>Márminálunk</title>
      </Helmet>
      <Scrollbars style={{ width: "100%", height: "100%" }}>
        <div className={styles.container}>
          <Header />
          {children}
        </div>
        <Footer />
      </Scrollbars>
      {confirmModal ? (
        <Modal
          open={confirmModal.open}
          onClose={onConfirmModalClose}
          className={styles.modalContainer}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 250,
          }}
        >
          <Fade in={confirmModal.open}>
            <div className={styles.modal}>
              <h1>{confirmModal.title}</h1>
              <p>{confirmModal.text}</p>

              <div className={styles.buttons}>
                <Button
                  variant="outlined"
                  onClick={() => {
                    showConfirmModal(false)
                    confirmModal.action()
                  }}
                  className={`${styles.button} ${
                    confirmModal.destructive ? styles.destructive : styles.action
                  }`}
                >
                  {confirmModal.actionLabel ?? "Folytatás"}
                </Button>
                <Button onClick={onConfirmModalClose} variant="outlined" className={styles.button}>
                  {confirmModal.cancelLabel ?? "Mégse"}
                </Button>
              </div>
            </div>
          </Fade>
        </Modal>
      ) : null}
    </>
  )
}
export default PageBase
