import React from "react"
import styles from "./EllipseSelect.module.scss"

interface Props {
	label1: string
	label2: string
	action1: () => void
	action2: () => void
	transformed: boolean
}

const EllipseSelect: React.FC<Props> = ({ label2, label1, action1, action2, transformed }) => {
	return (
		<div className={styles.container}>
			<div className={[styles.slider, transformed ? styles.transformed : ""].join(" ")} />
			<div
				className={[styles.text, transformed ? "" : styles.selectedText].join(" ")}
				onClick={action1}
			>
				<p>{label1}</p>
			</div>
			<div
				className={[styles.text, transformed ? styles.selectedText : ""].join(" ")}
				onClick={action2}
			>
				<p>{label2}</p>
			</div>
		</div>
	)
}
export default EllipseSelect
