import axios from "axios"
import { APIBASEURL } from "src/constants"
import { ApiResponse } from "src/types"

export const deleteMasterImageAdmin = async (imageId: number) => {
  const res = await axios({
    method: "DELETE",
    url: APIBASEURL + `master/image/${imageId}`,
    withCredentials: true,
  })

  return res.data as ApiResponse<string>
}
