import React, { useRef, useState } from 'react'
import { MMExperience } from '../../../types'
import Order from './Order'
import styles from './OrderDesktopWrapper.module.scss'

interface Props {
  experience: MMExperience
}

const OrderDesktopWrapper: React.FC<Props> = ({ experience }) => {
  const [orderModalOpen, setOrderModalOpen] = useState(false)
  const [style, setStyle] = useState<React.CSSProperties | undefined>(undefined)
  const stickyPosition = useRef<{ top: number; left: number } | null>(null)
  const initialScrollPosition = useRef(0)

  const ref = useRef<HTMLDivElement>(null)

  const handleModalStateChange = (value: boolean) => {
    if (!ref.current) return
    if (value) {
      const { top, left } = ref.current?.getClientRects()[0]
      stickyPosition.current = { top, left }
      initialScrollPosition.current = window.scrollY
      setStyle({ position: 'fixed', top, left, margin: 0 })
      setTimeout(() => {
        setStyle({
          position: 'fixed',
          left: window.innerWidth / 2 - 600 / 2,
          top: '6rem',
          margin: 0,
          width: 600,
          height: '80%',
          transition: 'left 0.4s, top 0.4s, width 0.4s',
        })
        setOrderModalOpen(true)
      }, 25)
      return
    }
    const scrollDifference = window.scrollY - initialScrollPosition.current
    setStyle({
      position: 'fixed',
      top: stickyPosition.current ? stickyPosition.current.top - scrollDifference : undefined,
      left: stickyPosition.current?.left,
      transition: 'left 0.4s, top 0.4s, width 0.4s',
      margin: 0,
    })
    setOrderModalOpen(false)
    setTimeout(() => {
      setStyle(undefined)
    }, 500)
  }

  return (
    <>
      <div style={style} ref={ref} className={styles.order}>
        <Order setIsOpen={handleModalStateChange} isOpen={orderModalOpen} experience={experience} />
      </div>
      {orderModalOpen ? <div className={styles.darkBg}></div> : null}
    </>
  )
}
export default OrderDesktopWrapper
