import React from "react"
import styles from "./ProfileDesktop.module.scss"
import Preferences from "./Preferences"
import { PastEventData } from "../../types"

interface Props {
	pastEvents: JSX.Element[]
}

const ProfileDesktop: React.FC<Props> = ({ pastEvents }) => {
	return (
		<div className={styles.container}>
			<div className={[styles.box].join(" ")}>
				<div className={styles.prefContainer}>
					<Preferences />
				</div>
			</div>
			<div className={styles.box}>
				<div className={styles.eventsContainer}>
					<p className={styles.blockTitle}>Korábbi élmények</p>
					{pastEvents}
				</div>
			</div>
		</div>
	)
}
export default ProfileDesktop
