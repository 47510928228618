import React, { useState } from "react"
import styles from "./Order.module.scss"
import OrderEvent from "./OrderEvent"
import { MMEvent, MMExperience } from "../../../types"
import MMButton from "../../../common-components/MMButton/MMButton"
import SlideLayout from "../../../common-components/SlideLayout/SlideLayout"
import OrderSummary from "./OrderSummary"
import ChooseDate from "./ChooseDate"

interface Props {
  experience: MMExperience
  isOpen: boolean
  setIsOpen: (v: boolean) => void
}

const Order: React.FC<Props> = ({ experience, isOpen, setIsOpen }) => {
  const [selectedEvent, setSelectedEvent] = useState<MMEvent | null>(null)
  const [transformed, setTransformed] = useState(false)

  const handleFinished = () => {
    setTransformed(false)
    setIsOpen(false)
  }

  return (
    <div className={styles.container}>
      {isOpen ? (
        <i
          onClick={() => {
            setIsOpen(false)
          }}
          className={["material-icons", styles.exitIcon].join(" ")}
        >
          close
        </i>
      ) : null}
      <SlideLayout customTransformed={transformed} customSetTransformed={setTransformed}>
        <ChooseDate
          setSelectedEvent={setSelectedEvent}
          experience={experience}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
        />
        <OrderSummary
          onFinished={handleFinished}
          price={experience.price}
          selectedEvent={selectedEvent}
          isOpen={isOpen}
        />
      </SlideLayout>
    </div>
  )
}
export default Order
