import { Button } from "@material-ui/core"
import React, { useState } from "react"
import Loader from "../Loader/Loader"
import styles from "./MMButton.module.scss"

interface Props {
	theme?: "default" | "callToAction" | "fab"
	className?: string
	handleClick?: () => void
	icon?: string
	iconClassName?: string
	logoAbsolute?: boolean
	disabled?: boolean
}

const MMButton: React.FC<Props> = ({
	children,
	theme = "default",
	className,
	handleClick,
	icon,
	iconClassName,
	logoAbsolute,
	disabled,
}) => {
	const [loading, setLoading] = useState(false)

	const handleClickWithLoading = async () => {
		if (loading) return
		const result = handleClick?.() as any
		if (result instanceof Promise) {
			setLoading(true)
			await result
			setLoading(false)
		}
	}

	return (
		<div className={[styles.container, className || ""].join(" ")}>
			<Button
				onClick={handleClickWithLoading}
				disabled={disabled}
				style={{ opacity: disabled ? 0.5 : 1 }}
				classes={{ root: styles[theme] }}
			>
				{icon ? (
					<div className={styles.buttonContent}>
						<i
							className={[
								"material-icons",
								logoAbsolute ? styles.logoAbsolute : styles.logo,
								iconClassName || "",
							].join(" ")}
						>
							{icon}
						</i>
						<p className={styles.iconButtonText}>{children}</p>
					</div>
				) : loading ? (
					<Loader color={theme === "default" ? "primary" : "white"} width="1.5rem" />
				) : (
					children
				)}
			</Button>
		</div>
	)
}
export default MMButton
