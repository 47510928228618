import axios from "axios"
import { APIBASEURL } from "../constants"
import { ApiResponse, MMExperience, MMExperienceBasicData } from "../types"

export const getFilteredExperiences = async ({
	numOfGuests = 0,
	type = null,
	endTime = null,
	startTime = null,
	filterLocation = null,
	filterRadius = null,
}: {
	startTime?: number | null
	endTime?: number | null
	type?: string | null
	numOfGuests?: number
	filterLocation?: string | null
	filterRadius?: number | null
}) => {
	const res = await axios({
		method: "get",
		url: APIBASEURL + "experiences",
		withCredentials: true,
		params: {
			numOfGuests,
			type,
			endTime,
			startTime,
			filterRadius,
			filterLocation,
		},
	})

	return res.data as ApiResponse<MMExperienceBasicData[]>
}
