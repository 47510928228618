import axios from "axios"
import { APIBASEURL } from "src/constants"
import { ApiResponse, ExperienceImageMetadata } from "src/types"

export const createExperienceImageAdmin = async (
  experienceID: number,
  metadata: ExperienceImageMetadata,
  image: File
) => {
  const formData = new FormData()
  formData.append("meta", JSON.stringify(metadata))
  formData.append("image", image)
  const res = await axios({
    method: "POST",
    url: APIBASEURL + `admin/experience/${experienceID}/image`,
    data: formData,
    withCredentials: true,
  })

  return res.data as ApiResponse<string>
}
