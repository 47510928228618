import React, {useEffect, useState} from 'react';
import ExperienceCard from "../../common-components/ExperienceCard/ExperienceCard"
import { getFilteredPastExperiences } from '../../apicalls/getFilteredPastExperiences'
import { useMediaQuery } from '@material-ui/core'
import { MMExperienceBasicData } from 'src/types';
import styles from './ExpiredExperiences.module.scss'

const ExpiredExperiences = () => {
  const [pastExperiences, setPastExperiences] = useState<MMExperienceBasicData[] | null>(null)
  const isMobile = useMediaQuery('(max-width: 600px)')

  useEffect(() => {
    const getPastExperiences = async () => {
      const res = await getFilteredPastExperiences({})
      setPastExperiences(res.data);
    }
    getPastExperiences();
  }, [])

  return (
    <div className={styles.wrapper}>
      <div id='cont' className={isMobile ? styles.containerMobile : styles.containerDesktop}>
        {pastExperiences && pastExperiences.map((experience, index) => (
          <ExperienceCard key={index} experience={experience} isDisabled={true} />
        ))}
      </div>
    </div>
  )
}

export default ExpiredExperiences